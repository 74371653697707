import React from "react";

interface Props {
  name: string;
  handleChange: any;
  value: any;
  label?: string;
  onBlur?: any;
  error?: string;
  disabled?: boolean;
  type?: string;
  touched?: any;
  containerClasses?: any;
  placeholder?: string;
  extraClasses?: string;
}

const InputField: React.FC<Props> = ({
  label,
  name,
  value,
  handleChange,
  onBlur,
  error = "",
  disabled = false,
  type = "text",
  touched = false,
  placeholder,
  extraClasses,
  containerClasses = null,
}) => {
  const isError = error === "" || error === "undefined" ? false : true;
  return (
    <>
      <div className={containerClasses ? containerClasses : "w-full mb-1"}>
        {label && (
          <label className="mb-1 text-gray-600 text-[12.6px]">{label}</label>
        )}
        <input
          type={type}
          className={
            extraClasses !== undefined
              ? `${extraClasses} w-full px-2 py-2 text-sm bg-white border-2 rounded-2`
              : `w-full px-2 py-2 text-sm bg-white border-2 rounded-2`
          }
          value={value}
          name={name}
          onChange={handleChange}
          disabled={disabled}
          placeholder={placeholder}
          autoComplete="off"
          onBlur={onBlur}
        />
        {isError && (
          <small className="mt-2 text-red-400" style={{ fontSize: 11 }}>
            {error}
          </small>
        )}
      </div>
    </>
  );
};

export default InputField;
