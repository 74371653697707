import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Loading from "./pages/Loading";
import SignUp from "./pages/auth/SignUp";
import LinkStale from "./pages/auth/LinkStale";
import * as Sentry from "@sentry/browser";
import Login from "./pages/auth/Login";
import ForgotPassword from "./pages/auth/ForgotPassword";
import VerifyEMail from "./pages/auth/VerifyEMail";
import Join from "./pages/auth/Join";
import VerifyingEmail from "./pages/auth/VerifyingEmail";
import ChangePassword from "./pages/auth/ChangesPassword";
import CompleteForm from "./pages/Transactions/CompleteForm";
import PasswordReset from "./pages/auth/PasswordReset";
import ProtectedRoute from "./components/ProtectedRoute";
import { Layout } from "./components/layout/Layout";
import { useAppDispatch, useAppSelector } from "./store/hooks";
import { useIntercom } from "react-use-intercom";
import PayOnDemand from "./pages/Transactions/PayOnDemand";
// import NotAvailable from "./NotAvailable";
import i18n from "i18next";
import PayLine from "./pages/Transactions/PayLine";
import TrueLayer from "./pages/Transactions/TrueLayer";
import {
  currentBalance,
  getAccounts,
  getDanapayBanks,
  getTransferCountries,
  getTransfers,
  getUsersMFIAccounts,
} from "./store/features/Transfer/Transfer";
import {
  addLoggedInUserCountry,
  fetchTransferRecords,
  resetTransfer,
  setIsFetching,
  updateCountries,
  updateDanaPayCountries,
} from "./store/features/Transfer/TransferSlice";
import NotAvailable from "./NotAvailable";
import BridgePaymentStatus from "./pages/Transactions/BridgePaymentStatus";
import MobileMoney from "./pages/Transactions/MobileMoney";
import Pusher from "pusher-js";
import Echo from "laravel-echo";
import { toast } from "material-react-toastify";
import { buildString } from "./utilities/help";
import {
  broadcastingAuth,
  fetchMMAccounts,
  getFavorites,
  logout,
} from "./store/features/Auth/Auth";
import { useTranslation } from "react-i18next";
import {
  editUser,
  refreshCaptchaToken,
  resetAuth,
  saveUserFavorites,
  setAccounts,
  setMFIAccounts,
  setMMAccounts,
  setnotificationState,
  updateBalance,
  updateDanaPayBankAccounts,
} from "./store/features/Auth/AuthSlice";
import { resetDeveloper } from "./store/features/Developer/DeveloperSlice";

var laravelEcho: any = null;
declare global {
  interface Window {
    Pusher: any;
    Echo: Echo;
  }
}

const Navigation: React.FC<any> = () => {
  const {
    auth: { user, lang },
  } = useAppSelector((state) => state.persistedReducer);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { boot } = useIntercom();
  const bootWithProps = React.useCallback(
    () => boot({ name: user?.first_name || "" }),
    [boot, user?.first_name]
  );

  useEffect(() => {
    try {
      // fetch_dana_pay_countries();
      i18n.changeLanguage(lang);
      bootWithProps();
    } catch (error: any) {
      Sentry.captureException(error);
    }
  }, [bootWithProps, lang]);

  useEffect(() => {
    const fetch_dana_pay_countries = () => {
      try {
        getTransferCountries()
          .then((response) => {
            dispatch(updateDanaPayCountries(response.data));
          })
          .catch((error: any) => {
            Sentry.captureException(error);
            toast.warning(t(error?.data?.message));
          });
      } catch (error: any) {
        Sentry.captureException(error);
      }
    };
    fetch_dana_pay_countries();
  }, []);

  const fetchAllRequired = async () => {
    dispatch(setIsFetching(true));
    try {
      const result: any = await Promise.allSettled([
        getFavorites(),
        getTransfers(1),
        getTransferCountries(),
        getAccounts(),
        getDanapayBanks(user?.country_code),
        getUsersMFIAccounts(),
        fetchMMAccounts(),
      ]);
      result[1]?.value !== undefined &&
        dispatch(fetchTransferRecords(result[1]?.value?.data));
      result[0]?.value !== undefined &&
        dispatch(saveUserFavorites(result[0]?.value));
      result[2]?.value !== undefined &&
        dispatch(updateCountries([result[2]?.value?.data, []]));
      result[2]?.value !== undefined &&
        dispatch(
          addLoggedInUserCountry(
            result[2]?.value?.data.find(
              (cc: any) => cc.country_code === user?.country_code
            )
          )
        );
      result[3]?.value !== undefined &&
        dispatch(setAccounts(result[3]?.value?.data));
      result[5]?.value !== undefined &&
        dispatch(setMFIAccounts(result[5]?.value?.data));
      result[6]?.value !== undefined &&
        dispatch(setMMAccounts(result[6]?.value?.data));
      result[4]?.value !== undefined &&
        dispatch(updateDanaPayBankAccounts(result[4]?.value?.bank_accounts));
      dispatch(setIsFetching(false));
    } catch (error: any) {
      const { status } = error;
      dispatch(setIsFetching(false));
      // eslint-disable-next-line
      if (status == 401) {
        fetchAllRequired();
      }
    }
  };

  const fetchCurrentUserState = () => {
    dispatch(setIsFetching(true));
    try {
      currentBalance()
        .then((res: any) => {
          const verificationResult = res?.verification_result;
          const userRejected = res?.is_rejected;
          if (verificationResult == "RED-FINAL" || userRejected == true) {
            logout()
              .then(() => {
                localStorage.removeItem("user:key");
                localStorage.removeItem("withdrawEnabled");
                localStorage.removeItem("paymentError");
                localStorage.removeItem("depositattempts");
                localStorage.removeItem("maketransferattempts");
                dispatch(resetAuth());
                dispatch(resetTransfer());
                dispatch(resetDeveloper());
                window.location.href = "/login";
                dispatch(refreshCaptchaToken(false));
              })
              .catch((error: any) => {
                Sentry.captureException(error);
                toast.error(t(error?.data?.message));
              });
          } else {
            dispatch(setIsFetching(false));
            dispatch(updateBalance(res.client.euro_balance));
            dispatch(editUser(res));
          }
        })
        .catch((error: any) => {
          dispatch(setIsFetching(false));
          Sentry.captureException(error);
          toast.warning(t(error?.data?.message));
        });
    } catch (error: any) {
      Sentry.captureException(error);
    }
  };

  window.Pusher = Pusher;
  const loggedInKey = window.localStorage.getItem("user:key");

  useEffect(() => {
    try {
      const isUpdating = window.localStorage.getItem("isUpdate");
      const isLoggedIn = window.localStorage.getItem("user:key");
      if (user == null && laravelEcho !== null && isUpdating == null) {
        laravelEcho.disconnect();
        laravelEcho = null;
      }

      if (user?.is_rejected == true) {
        fetchCurrentUserState();
        if (laravelEcho !== null && isUpdating == null) {
          laravelEcho.disconnect();
          laravelEcho = null;
        }
      }

      const {
        REACT_APP_WSHOST,
        REACT_APP_WSPORT,
        REACT_APP_WSSPORT,
        REACT_APP_FORCE_TLS,
      } = process.env;
      if (laravelEcho == null && user !== null && isLoggedIn) {
        laravelEcho = new Echo({
          broadcaster: "pusher",
          key: "1ec7db3d-24ad-62a0-b0d1-0242ac110002",
          wsHost: REACT_APP_WSHOST,
          wsPort: REACT_APP_WSPORT,
          wssPort: REACT_APP_WSSPORT,
          forceTLS: REACT_APP_FORCE_TLS === "true",
          encrypted: true,
          disableStats: true,
          enabledTransports: ["ws", "wss"],
          cluster: "mt1",
          authorizer: (channel: any, options: any) => {
            return {
              authorize: (socketId: any, callback: any) => {
                broadcastingAuth(socketId, channel)
                  .then((response: any) => {
                    callback(false, response);
                  })
                  .catch((error: any) => {
                    callback(true, error);
                    Sentry.captureException(error);
                    toast.warning(t(error?.data?.message));
                  });
              },
            };
          },
        });

        laravelEcho
          .channel("DPPublicNotificationChannel")
          .listen(`VersionChange`, (e: any) => {
            try {
              dispatch(
                setnotificationState({
                  show: true,
                })
              );
            } catch (error: any) {
              Sentry.captureException(error);
            }
          });

        laravelEcho
          .private(`User.${user?.id}`)
          .listen(`kYCOnBoardingRejected`, () => {
            try {
              fetchCurrentUserState();
            } catch (error) {
              Sentry.captureException(error);
            }
          });

        if (user?.company?.id !== undefined) {
          laravelEcho
            .private(`Users.Models.Company.${user?.company?.id}`)
            .listen("CompanyActivityStatusChange", (e: any) => {
              toast.info(t(`${buildString(e?.message.toLowerCase())}`));
              fetchCurrentUserState();
            });
        }

        if (user?.id !== undefined) {
          laravelEcho
            .private(`User.${user?.id}`)
            .listen("PayinStatusChange", (e: any) => {
              if (e.title) {
                toast.info(t(`${buildString(e?.message.toLowerCase())}`));
                fetchAllRequired();
              }
            });

          laravelEcho
            .private(`User.${user?.id}`)
            .listen("CashoutStatusChange", (e: any) => {
              if (e.title) {
                toast.info(t(`${buildString(e?.message.toLowerCase())}`));
                fetchAllRequired();
              }
            });

          laravelEcho
            .private(`User.${user?.id}`)
            .listen("TransferStatusChange", (e: any) => {
              if (e.title) {
                toast.info(t(`${buildString(e?.message.toLowerCase())}`));
                fetchAllRequired();
              }
            });

          laravelEcho
            .private(`User.${user?.id}`)
            .listen("UserActivityStatusChange", (e: any) => {
              if (e.title) {
                toast.info(t(`${buildString(e?.message.toLowerCase())}`));
                const external_link = localStorage.getItem(
                  "ext_mm_process_link"
                );
                if (external_link) {
                  fetch(external_link)
                    .then((res: any) => res.json())
                    .then((response: any) => {
                      if (response.redirect_uri) {
                        window.location?.replace(response.redirect_uri);
                      } else {
                      }
                    })
                    .catch((error: any) => {
                      Sentry.captureException(error);
                      toast.warning(t(error?.data?.message));
                    });
                } else {
                  fetchCurrentUserState();
                }
              }
            });

          laravelEcho
            .private(`User.${user?.id}`)
            .listen("PaymentStatusChange", (e: any) => {
              if (e.title) {
                fetchAllRequired();
                toast.info(t(`${buildString(e?.message.toLowerCase())}`));
              }
            });
        }
      }
    } catch (error: any) {
      Sentry.captureException(error);
    }
  }, [user, loggedInKey]);

  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Loading} />
        {/* <Route path="/" component={NotAvailable} /> */}

        <Route exact path="/login" component={Login} />
        <Route exact path="/verify" component={VerifyEMail} />
        <Route exact path="/verifyingEmail" component={VerifyingEmail} />
        <Route exact path="/stale" component={LinkStale} />
        <Route exact path="/join" component={Join} />
        <Route exact path="/changePassword" component={ChangePassword} />
        <Route exact path="/forgot" component={ForgotPassword} />
        <Route exact path="/passwordReset" component={PasswordReset} />
        <ProtectedRoute exact path="/register" component={SignUp} />
        <ProtectedRoute exact path="/complete_form" component={CompleteForm} />
        <ProtectedRoute exact path="/payment/:id" component={PayOnDemand} />
        <ProtectedRoute path="/dashboard" component={Layout} />
        <ProtectedRoute exact path="/truelayer" component={TrueLayer} />
        <ProtectedRoute
          exact
          path="/bridgeapi/payment_status"
          component={BridgePaymentStatus}
        />
        <ProtectedRoute exact path="/payline/:status" component={PayLine} />
        <ProtectedRoute exact path="/mobile_money" component={MobileMoney} />
      </Switch>
    </Router>
  );
};

export default Navigation;
