import { ots } from "../TransactionListItem/data";

const TransactionIcon = (props: any) => {
  const {
    transaction,
    operation_type,
    iconSize = 20,
    isActiveSender,
    height = 35,
    width = 35,
    borderRadius = 20,
    customIcon = null,
    isInDetailsDrawer = false,
  } = props;

  return (
    <>
      {/* receiving */}
      {transaction?.operation_direction?.toLowerCase() === "receiving" &&
        (operation_type?.toLowerCase() === "transfer" ||
          operation_type?.toLowerCase() === "direct transfer") && (
          <span
            className="flex items-center justify-center bg-orange-200"
            style={{ height: height, width: width, borderRadius: borderRadius }}
          >
            <i
              style={{ fontSize: iconSize }}
              className={
                transaction?.transfer?.bulk_transfer_recipient_details?.length >
                0
                  ? customIcon
                    ? customIcon
                    : "fa fa-flash  m-0 text-green-500"
                  : isInDetailsDrawer
                  ? `fa fa-long-arrow-down  m-0 text-orange-500`
                  : `fa fa-arrow-down  m-0 text-orange-500`
              }
            ></i>
          </span>
        )}

      {/* sending direct transfer */}
      {transaction?.operation_direction?.toLowerCase() === "sending" &&
        (operation_type?.toLowerCase() === "transfer" ||
          operation_type?.toLowerCase() === "direct transfer") && (
          <span
            className="flex items-center justify-center bg-green-200 flex-shrink-0"
            style={{ height: height, width: width, borderRadius: borderRadius }}
          >
            <i
              style={{ fontSize: iconSize }}
              className={
                transaction?.transfer?.bulk_transfer_recipient_details?.length >
                0
                  ? customIcon
                    ? customIcon
                    : "fa fa-flash  m-0 text-green-500"
                  : isInDetailsDrawer
                  ? `fa fa-long-arrow-up  m-0 text-green-500`
                  : `fa fa-arrow-up  m-0 text-green-500`
              }
            ></i>
          </span>
        )}

      {/* sending normal transfer */}
      {/* {operation_type === "instant_transfer" && (
        <>
          {isActiveSender ? (
            <span
              className="flex items-center justify-center bg-green-200 flex-shrink-0"
              style={{
                height: height,
                width: width,
                borderRadius: borderRadius,
              }}
            >
              <i
                style={{ fontSize: iconSize }}
                className={
                  isInDetailsDrawer
                    ? `fa fa-long-arrow-up  m-0 text-green-500`
                    : `fa fa-arrow-up  m-0 text-green-500`
                }
              ></i>
            </span>
          ) : (
            <span
              className="flex items-center justify-center bg-orange-200"
              style={{
                height: height,
                width: width,
                borderRadius: borderRadius,
              }}
            >
              <i
                style={{ fontSize: iconSize }}
                className={
                  isInDetailsDrawer
                    ? `fa fa-long-arrow-down  m-0 text-orange-500`
                    : `fa fa-arrow-down  m-0 text-orange-500`
                }
              ></i>
            </span>
          )}
        </>
      )} */}

      {/* deposit and withdraw */}
      {operation_type?.toLowerCase() !== "transfer" &&
        operation_type?.toLowerCase() !== "direct transfer" && (
          <span
            className="flex items-center justify-center bg-green-200 flex-shrink-0"
            style={{ height: height, width: width, borderRadius: borderRadius }}
          >
            <i
              style={{ fontSize: iconSize }}
              className={`${
                ots[operation_type?.toLowerCase()]?.icon
              } m-0 text-green-500`}
            ></i>
          </span>
        )}
    </>
  );
};

export default TransactionIcon;
