import Skeleton from "react-loading-skeleton";

const TransactionLoadingShimmer = (props: any) => {
  const { t } = props;
  return (
    <div className="w-full p-4 fh-full" style={{ height: 600 }}>
      <Skeleton height={60} />
      <br />
      <Skeleton height={30} />
      <br />
      <Skeleton height={360} />
    </div>
  );
};

export default TransactionLoadingShimmer;
