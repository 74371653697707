import React, { useMemo } from "react";
import CustomDropdown from "../CustomDropdown";
import InputField from "../forms/InputField";
import * as Sentry from "@sentry/browser";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../store/hooks";

const BankWithdrawBox = (props: any) => {
  const {
    bankSelectionList,
    setwidthdrawMethod,
    widthdrawMethod,
    setcustomBankName,
    setinstitutionId,
    setselectedBankName,
    selectedBankName,
    customBankName,
    setIBAN,
    iBAN,
    customIban,
    setcustomIban,
    accountHolderName,
    beneficiaryBankAccounts,
    directPaymentAmountExceed,
  } = props;
  const { t } = useTranslation();
  const { transfer } = useAppSelector((state) => state.persistedReducer);

  const maxLimit = useMemo(() => {
    try {
      if (
        transfer?.getAllMobileOperator &&
        transfer?.getAllMobileOperator?.length > 0
      ) {
        const mobileOperatorObject = transfer?.getAllMobileOperator.find(
          (cashoutMethod: any) => {
            return (
              cashoutMethod &&
              cashoutMethod.cashout_method &&
              cashoutMethod.cashout_method.payment_type &&
              (cashoutMethod.cashout_method.payment_type.name ==
                "manual_bank_transfer" ||
                cashoutMethod.cashout_method.payment_type.name ==
                  "bank_transfer")
            );
          }
        );
        if (mobileOperatorObject) {
          return mobileOperatorObject.cashout_method.max_amount + "€";
        }
      } else {
        return 0;
      }
    } catch (error) {
      Sentry.captureException(error);
      return 0;
    }
  }, [transfer]);

  return (
    <>
      {bankSelectionList.length > 0 && (
        <>
          <div className="wrapperBankCheckBox m-0 innerBankBox">
            <div className="wrapperBankCheckBoxwrapper">
              <input
                type="checkbox"
                className="default:ring-2"
                onChange={(e) => {
                  e.target.checked && setwidthdrawMethod("Bank Account");
                }}
                name="Mobile Money"
                checked={widthdrawMethod == "Bank Account"}
              />
              <span className="checkmark"></span>
            </div>
            <label
              className={`opacity-[${
                directPaymentAmountExceed && widthdrawMethod == "Bank Account"
                  ? 0.5
                  : 1
              }]`}
            >
              {t("Bank Account")}
            </label>
          </div>

          {directPaymentAmountExceed && widthdrawMethod == "Bank Account" && (
            <small className="mb-2 innerBankBox text-red-500">
              {t("max_amount_exceeded_cashout_message_1")}
              {maxLimit}
              {t("max_amount_exceeded_cashout_message_2")}
            </small>
          )}

          {widthdrawMethod == "Bank Account" &&
            bankSelectionList.length > 0 && (
              <div className="flex mb-2 flex-col gap-1 innerBankBox">
                <div className="innerBoxDesc mb-2">
                  {t("PleaseSpecifyYourRecipient")}
                </div>

                {bankSelectionList.length > 0 ? (
                  <CustomDropdown
                    isDisabled={
                      directPaymentAmountExceed &&
                      widthdrawMethod == "Bank Account"
                    }
                    setCustomProvider={setcustomBankName}
                    defaultValue={
                      transfer?.transactionToRepeat?.data?.bank_name
                    }
                    setinstitutionId={setinstitutionId}
                    onchange={(val: any) => {
                      setselectedBankName(val);
                    }}
                    list={bankSelectionList}
                    customClasses="wrapperBankCheckBox innerBankBox m-0 !bg-white"
                    placeholder={t("select_the_bank")}
                  />
                ) : (
                  <InputField
                    disabled={
                      directPaymentAmountExceed &&
                      widthdrawMethod == "Bank Account"
                    }
                    handleChange={(e: any) =>
                      setselectedBankName(e.target.value)
                    }
                    value={selectedBankName}
                    label=""
                    type="text"
                    name="Bank name"
                    placeholder="Bank name"
                  />
                )}

                {selectedBankName == "Other" && (
                  <InputField
                    disabled={
                      directPaymentAmountExceed &&
                      widthdrawMethod == "Bank Account"
                    }
                    handleChange={(e: any) => setcustomBankName(e.target.value)}
                    value={customBankName}
                    label=""
                    type="text"
                    name="customBankName"
                    placeholder={t("Bank_Name")}
                  />
                )}

                {/* fixed account holder name */}
                <div className="relative flex">
                  <div
                    className={`innerBoxDesc px-4 justify-center overflow-hidden inset-y-0 flex items-center bg-red left-0`}
                    style={{
                      borderRadius: "10px",
                      borderTopRightRadius: 0,
                      borderBottomRightRadius: 0,
                      backgroundColor: "#e8e7e7",
                      border: "1px solid #e8e8e8",
                      borderRight: "none",
                      marginBlock: "7px",
                    }}
                  >
                    {t("owner_tag")}
                  </div>
                  <input
                    type="text"
                    className="owner w-full text-[#8b8b8b]"
                    value={accountHolderName}
                    disabled={true}
                    name="accountHolderName"
                    placeholder={t("receivers_fullname")}
                  />
                </div>

                {/* show bene accounts */}
                {beneficiaryBankAccounts.length > 0 ? (
                  <CustomDropdown
                    isDisabled={
                      directPaymentAmountExceed &&
                      widthdrawMethod == "Bank Account"
                    }
                    setCustomProvider={setcustomIban}
                    defaultValue={transfer?.transactionToRepeat?.data?.iban}
                    onchange={(val: any) => {
                      setIBAN(val);
                    }}
                    list={beneficiaryBankAccounts}
                    customClasses="wrapperBankCheckBox innerBankBox m-0 !bg-white"
                    placeholder={t("Select IBAN")}
                  />
                ) : (
                  <InputField
                    disabled={
                      directPaymentAmountExceed &&
                      widthdrawMethod == "Bank Account"
                    }
                    handleChange={(e: any) => setIBAN(e.target.value)}
                    value={iBAN}
                    label=""
                    type="text"
                    name="IBAN"
                    placeholder="IBAN"
                  />
                )}

                {/* other iban */}
                {iBAN == "Other" && (
                  <InputField
                    disabled={
                      directPaymentAmountExceed &&
                      widthdrawMethod == "Bank Account"
                    }
                    handleChange={(e: any) => setcustomIban(e.target.value)}
                    value={customIban}
                    label=""
                    type="text"
                    name="IBAN"
                    placeholder="IBAN"
                  />
                )}
              </div>
            )}
        </>
      )}
    </>
  );
};

export default BankWithdrawBox;
