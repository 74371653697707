import React, { useState, useLayoutEffect, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Route, Link, useHistory } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { Drawer } from "@material-ui/core";
import Skeleton from "react-loading-skeleton";
import * as Sentry from "@sentry/browser";
import "react-loading-skeleton/dist/skeleton.css";
import Avatar from "react-avatar";
import "./style.css";
import "../../helpers/i18n";
import Home from "../../pages/Home";
import Apps from "../../pages/developer/Apps";
import History from "../../pages/History";
import Profile from "../../pages/auth/Profile";
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import {
  editUser,
  refreshCaptchaToken,
  resetAuth,
  resetAuthForNewVersion,
  saveUserFavorites,
  setAccounts,
  setActiveMenu,
  setMFIAccounts,
  setMMAccounts,
  setnotificationState,
  updateBalance,
  updateDanaPayBankAccounts,
} from "../../store/features/Auth/AuthSlice";
import {
  addUser,
  discovery,
  fetchMMAccounts,
  logout,
} from "../../store/features/Auth/Auth";

import {
  addLoggedInUserCountry,
  closeModal,
  fetchTransferRecords,
  openBulkTransferDialog,
  openDeposit,
  openWithdraw,
  resetTransfer,
  setIsFetching,
  setStep,
  setTLError,
  setTransactionToRepeat,
  updateBulkTData,
  updateCountries,
} from "../../store/features/Transfer/TransferSlice";
import { resetDeveloper } from "../../store/features/Developer/DeveloperSlice";
import {
  currentBalance,
  getAccounts,
  getDanapayBanks,
  getTransferCountries,
  getTransfers,
  getUsersMFIAccounts,
} from "../../store/features/Transfer/Transfer";
import { getFavorites } from "../../store/features/Auth/Auth";
import CurrencySwitch from "../CurrencySwitch";
import LangSwitch from "../LangSwitch";
import TransactionDetails from "../TransactionDetails/TransactionDetails";
import { toast } from "material-react-toastify";

import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";
import MakeTransfer from "../../pages/Transactions/MakeTransfer";
import {
  setBeneFound,
  setTransferDialogOpen,
} from "../../store/features/Transfer/TransferSlice";
import ls from "@livesession/sdk";
import useResize from "../../hooks/useResize";
import package_json from "../../../package.json";
import Cashout from "../../pages/Transactions/Cashout";
import Deposit from "../../pages/Transactions/Deposit";
import BulkPayment from "../BulkPayment";
import DiscoveryModal from "../DiscoveryModal/DiscoveryModal";
import NotificationModalOverlay from "../NotificationModalOverlay/NotificationModalOverlay";
import SumsubRejectModal from "../Sumsub/SumsubRejectModal";
import { redirectToRegister } from "../../utilities/help";
import { Close } from "@material-ui/icons";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const Layout = () => {
  const isEmpty = (val: string) => {
    return val === undefined || val == null || val.length <= 0 ? true : false;
  };

  const { t } = useTranslation();
  const resize = useResize();
  const {
    transfer: {
      isModalOpened,
      selectedTransfer,
      page,
      fetching,
      isTransferDialogOpen,
      isDepositOpen,
      isWithdrawOpen,
      isBulkTransferDialogOpen,
    },
    auth: { user, active_menu, notificationState, signUpData },
  } = useAppSelector((state) => state.persistedReducer);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [visible, setVisible] = useState(false);
  const [width, setWidth] = useState(0);

  const clearTransferFailNotificationCallback = (fn: any) => {
    fn();
  };

  const [showDiscovery, setshowDiscovery] = useState(false);
  const [menus, setMenu] = useState([
    {
      name: "menuHome",
      icon: "fa fa-home",
      path: "home",
    },
    {
      name: "menuHistory",
      icon: "fa fa-bookmark",
      path: "history",
    },
    {
      name: "profile",
      icon: "fa fa-user",
      path: "profile",
    },
  ]);

  const checkLoggedIn = () => {
    if (localStorage.getItem("user:key")) {
      if (user?.is_individual) {
        if (isEmpty(user?.first_name) || isEmpty(user?.last_name)) {
          history.push("/register?page=1");
        } else if (isEmpty(user?.monthly_revenue)) {
          history.push("/register?page=2");
        }
      } else {
        if (isEmpty(user?.first_name) || isEmpty(user?.last_name)) {
          history.push("/register?page=1");
        } else if (!user?.company) {
          history.push("/register?page=2");
        }
      }
    } else {
      history.push("/login");
    }
  };

  const handleLogout = () => {
    confirmAlert({
      title: t("loggingOut"),
      message: t("loggingOutSure"),
      overlayClassName: "zIndexClass",
      closeOnEscape: true,
      closeOnClickOutside: true,
      buttons: [
        {
          label: t("yes"),
          onClick: () => {
            logout()
              .then((response: any) => {
                localStorage.removeItem("user:key");
                localStorage.removeItem("withdrawEnabled");
                localStorage.removeItem("paymentError");
                localStorage.removeItem("depositattempts");
                localStorage.removeItem("maketransferattempts");
                localStorage.removeItem("otpresendtime");
                dispatch(resetAuth());
                dispatch(resetTransfer());
                dispatch(resetDeveloper());
                history.push("/login");
                dispatch(refreshCaptchaToken(false));
                // history.push("/login", { state: { isLogout: true } });
              })
              .catch((error: any) => {
                Sentry.captureException(error);
                toast.error(t(error?.data?.message));
              });
          },
        },
        {
          label: t("no"),
          onClick: () => {},
        },
      ],
    });
  };

  const fetchCurrentUserState = () => {
    try {
      dispatch(setIsFetching(true));
      currentBalance()
        .then((res: any) => {
          if (res?.meta !== undefined && res?.meta !== null) {
            if (Array.isArray(res?.meta) && res?.meta.length < 1) {
              setshowDiscovery(true);
            } else {
              setshowDiscovery(false);
            }
          }
          dispatch(setIsFetching(false));
          dispatch(updateBalance(res.client.euro_balance));
          dispatch(editUser(res));
        })
        .catch((error: any) => {
          dispatch(setIsFetching(false));
          Sentry.captureException(error);
          toast.error(t(error?.data?.message));
        })
        .finally(() => window.localStorage.removeItem("isUpdate"));
    } catch (error: any) {
      Sentry.captureException(error);
    }
  };

  const setUserloggedInCountry = async (data: any) => {
    dispatch(setIsFetching(true));
    currentBalance()
      .then((response: any) => {
        dispatch(editUser(response));
        dispatch(
          addLoggedInUserCountry(
            data.find((cc: any) => cc.country_code === response?.country_code)
          )
        );
      })
      .finally(() => {
        dispatch(setIsFetching(false));
      });
  };

  const fetchAllRequired = async () => {
    dispatch(setIsFetching(true));

    try {
      const result: any = await Promise.allSettled([
        getFavorites(),
        getTransfers(page),
        getTransferCountries(),
        getAccounts(),
        getDanapayBanks(user?.country_code),
        getUsersMFIAccounts(),
        fetchMMAccounts(),
      ]);
      result[1]?.value !== undefined &&
        dispatch(fetchTransferRecords(result[1]?.value?.data));
      result[0]?.value !== undefined &&
        dispatch(saveUserFavorites(result[0]?.value));
      result[2]?.value !== undefined &&
        dispatch(updateCountries([result[2]?.value?.data, []]));
      result[2]?.value !== undefined &&
        setUserloggedInCountry(result[2]?.value?.data);

      result[3]?.value !== undefined &&
        dispatch(setAccounts(result[3]?.value?.data));
      result[5]?.value !== undefined &&
        dispatch(setMFIAccounts(result[5]?.value?.data));
      result[6]?.value !== undefined &&
        dispatch(setMMAccounts(result[6]?.value?.data));
      result[4]?.value !== undefined &&
        dispatch(updateDanaPayBankAccounts(result[4]?.value?.bank_accounts));
      dispatch(setIsFetching(false));
    } catch (error: any) {
      const { status } = error;
      dispatch(setIsFetching(false));
      if (status == 401) {
        fetchAllRequired();
      }
    }
  };

  const startBulkPayments = () => {
    try {
      if (isBulkTransferDialogOpen === true) {
        dispatch(openBulkTransferDialog("open"));
      } else {
        dispatch(updateBulkTData({ users: {} }));
        dispatch(updateBulkTData({ country: "france", reason: "" }));
        dispatch(openBulkTransferDialog("open"));
      }
    } catch (error: any) {
      Sentry.captureException(error);
    }
  };

  useLayoutEffect(() => {
    function updateSize() {
      setWidth(window.innerWidth);
      if (window.innerWidth < 800) {
        setVisible(false);
      } else {
        setVisible(true);
      }
    }
    // checkLoggedIn();
    redirectToRegister(user, signUpData, isEmpty, history);

    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, [user]);

  useEffect(() => {
    if (window.localStorage.getItem("isUpdate") == null) {
      fetchAllRequired();
      fetchCurrentUserState();
    }
  }, []);

  ls.identify({
    name: user?.full_name,
    email: user?.email,
  });

  const handleNewVersionReset = () => {
    dispatch(setIsFetching(true));
    window.localStorage.setItem("isUpdate", "yes");
    window.location.reload();
    // fetchCurrentUserState();
    // fetchAllRequired()
  };

  // set everything new for redux on new version
  const handleNewUpdate = () => {
    dispatch(resetAuthForNewVersion());
    dispatch(resetTransfer());
    dispatch(resetDeveloper());
    dispatch(
      setnotificationState({
        show: false,
      })
    );

    setTimeout(() => {
      fetchAllRequired();
      fetchCurrentUserState();
    }, 2000);
  };

  useEffect(() => {
    if (
      window.localStorage.getItem("isUpdate") !== undefined &&
      window.localStorage.getItem("isUpdate") !== null
    ) {
      handleNewUpdate();
    }
  }, []);

  return (
    <>
      <div
        className="fixed flex py-2 layoutmain flex-row justify-between w-screen bg-white shadow-md"
        style={{ zIndex: 99 }}
      >
        <div
          className="flex flex-row items-center justify-between"
          style={{ width: resize[0] > 1000 ? 220 : 10 }}
        >
          {resize[0] > 600 && (
            <img
              src="/images/logofull.png"
              style={{ height: 20 }}
              className="ml-3"
            />
          )}
        </div>
        <div className="flex flex-row items-center justify-between flex-1">
          <div className="flex flex-row">
            <Avatar name={user?.full_name} size="40" round />
            <div
              className="flex flex-col justify-between ml-4 text-xs"
              style={{ width: resize[0] > 600 ? 250 : "" }}
            >
              <b className="username text-sm">{user?.full_name}</b>
              {user?.is_individual ? (
                <>{t("Individual_Account")}</>
              ) : (
                <>{user?.company?.name} </>
              )}
            </div>
          </div>
          <div className="flex flex-row items-center">
            <div className="mr-4 max-sm:!mr-1">
              <CurrencySwitch />
            </div>
            <div className="mr-4 max-sm:!mr-1">
              <LangSwitch />
            </div>
            <a
              className="flex items-center justify-center mr-4 bg-gray-200 cursor-pointer"
              onClick={() => handleLogout()}
              style={{ height: 40, width: 40, borderRadius: 20 }}
            >
              <span>
                <i className="p-0 m-0 fa fa-sign-out"></i>
              </span>
            </a>
          </div>
        </div>
      </div>

      {/* update notification */}
      <NotificationModalOverlay
        open={notificationState?.show}
        onClick={() => {
          handleNewVersionReset();
        }}
      />

      <div className="flex flex-row overflow-hidden">
        {showDiscovery && (
          <div
            className="flex align-center absolute w-full h-full"
            style={{ background: "rgba(0,0,0,0.5)", zIndex: 999 }}
          >
            <DiscoveryModal
              isDashboardModal="true"
              extraclass={"w-full h-full items-center justify-center flex"}
              onNext={(values: any) => {
                addUser(
                  {
                    ...values,
                  },
                  "individual"
                )
                  .catch((err) => {
                    Sentry.captureException(err);
                    toast.warning(t(err?.data?.message));
                  })
                  .finally(() => {
                    setshowDiscovery(false);
                  });
              }}
            />
          </div>
        )}
        {resize[0] > 600 && (
          <div className="flex flex-col justify-between menu iconsMenu conHeight">
            <ul>
              {menus.map((menu: any, index: number) => (
                <li
                  onClick={() => {
                    dispatch(setActiveMenu(menu.path));
                    history.push(`/dashboard/${menu.path}`);
                  }}
                  key={index}
                  className={`${
                    active_menu === menu.path ? "active_menu" : "in_active_menu"
                  }`}
                >
                  <i className={`${menu.icon}`}></i>
                  <small>{t(menu.name)}</small>
                </li>
              ))}
            </ul>
            <ul>
              <li className="in_active_menu">
                <a
                  target="_blank"
                  href="https://www.danapay.com/conditions/conditions-g%C3%A9n%C3%A9rales-politique-de-confidentialit%C3%A9"
                >
                  <i className="fa fa-globe"></i>
                  <small>{t("menuTerms")}</small>
                </a>
              </li>
              <li onClick={() => handleLogout()} className="in_active_menu">
                <i className="fa fa-sign-out"></i>
                {visible && <small>Déconnexion</small>}
              </li>
              <li className="in_active_menu">
                {process.env.REACT_APP_ENV !== "Production" ? (
                  <small style={{ fontSize: 12 }}>
                    {/* Display the version without metadata - remove all after the + sign in the version */}
                    Version {package_json.version.split("+")[0]} (
                    {typeof process.env.REACT_APP_ENV === "undefined"
                      ? "Local"
                      : process.env.REACT_APP_ENV}
                    )
                  </small>
                ) : (
                  <small style={{ fontSize: 12 }}>
                    Version {package_json.version.split("+")[0]}
                  </small>
                )}
              </li>
            </ul>
          </div>
        )}

        <div
          className={
            visible
              ? "bg-gray-200 withMenu conHeight bgContent"
              : "withIconsMenu"
          }
        >
          {fetching ? (
            <div className="container">
              <b className="text-gray-400">
                <small>{t("Your_current_balance")}</small>
              </b>
              <Skeleton height={80} />
              <br />
              <b className="text-gray-400">
                <small>{t("Contacts")}</small>
              </b>
              <Skeleton height={140} />
              <br />
              <b className="text-gray-400">
                <small>{t("Latest_Transactions")}</small>
              </b>
              <Skeleton height={300} />
            </div>
          ) : (
            <>
              <Route path="/dashboard/home">
                <Home />
              </Route>
              <Route path="/dashboard/developer">
                <Apps />
              </Route>
              <Route path="/dashboard/history">
                <History />
              </Route>
              <Route path="/dashboard/profile">
                <Profile />
              </Route>
            </>
          )}
        </div>
      </div>

      <Drawer
        anchor="right"
        open={isModalOpened}
        variant="temporary"
        elevation={20}
        PaperProps={{ style: { width: width > 900 ? "40%" : "100%" } }}
      >
        <div className="h-screen transactiondetails">
          <div className="flex flex-col h-full">
            <div className="flex items-center justify-between p-2 pb-4">
              <button
                onClick={() => dispatch(closeModal())}
                style={{ height: 45, width: 45, borderRadius: 20 }}
                className="flex ml-auto items-center justify-center shadow-md"
              >
                <Close />
              </button>
            </div>
            <TransactionDetails
              selectedTransfer={selectedTransfer}
              close={() => dispatch(closeModal())}
            />
          </div>
        </div>
      </Drawer>
      <Dialog
        fullScreen
        className="dialogsx"
        open={isTransferDialogOpen}
        onClose={() => dispatch(setTransferDialogOpen(false))}
        TransitionComponent={Transition}
      >
        <div
          className="flex flex-row items-center justify-between px-10 py-2"
          style={{ backgroundColor: "rgb(3, 115, 117)" }}
        >
          <span className="text-white">{t("start_Transfer")}</span>

          <button
            data-transfer-cancel="transfer-cancel"
            onClick={() => {
              window.heap.track("Transfer canceled", {
                phone: user.full_phone_number,
                email: user.email,
                country: user.country,
                name: user.full_name,
              });
              dispatch(setBeneFound(false));
              dispatch(setStep(0));
              dispatch(setTLError(null));
              dispatch(setTransactionToRepeat(null));
              dispatch(setTransferDialogOpen(!isTransferDialogOpen));
            }}
            style={{ height: 40, width: 40, borderRadius: 20 }}
            className="flex items-center justify-center shadow-lg data-transfer-cancel"
          >
            <i
              className="m-0 text-white fa fa-close data-transfer-cancel"
              data-transfer-cancel="transfer-cancel"
            ></i>
          </button>
        </div>
        <MakeTransfer />
      </Dialog>
      <Dialog
        fullScreen
        open={isWithdrawOpen}
        onClose={() => dispatch(openWithdraw(false))}
        TransitionComponent={Transition}
      >
        <Cashout setWithDrawOpen={() => dispatch(openWithdraw(true))} />
      </Dialog>

      <Dialog
        fullScreen
        open={isDepositOpen}
        onClose={() => dispatch(openDeposit(false))}
        TransitionComponent={Transition}
      >
        <Deposit setDepositOpen={() => dispatch(openDeposit(false))} />
      </Dialog>

      <Dialog
        fullScreen
        open={isBulkTransferDialogOpen}
        onClose={startBulkPayments}
        TransitionComponent={Transition}
      >
        <BulkPayment setBulkPaymentModal={startBulkPayments} t={t} />
      </Dialog>

      {resize[0] < 600 && (
        <div
          style={{
            backgroundColor: "#fff",
            height: 56,
            width: "100%",
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 100,
            borderTopColor: "#037375",
            borderTopWidth: 4,
          }}
        >
          <div className="flex flex-row items-center justify-center">
            {menus.map((menu, menuIndex) => (
              <div
                key={menuIndex}
                className="flex flex-col items-center justify-center flex-1 h-full"
                style={{ height: 56 }}
              >
                <a
                  onClick={() => {
                    dispatch(setActiveMenu(menu.path));
                    history.push(`/dashboard/${menu.path}`);
                  }}
                  key={menu.name}
                  className="cursor-pointer"
                >
                  <i
                    className={`${menu.icon} mb-2 text-center`}
                    style={{ fontSize: 14, color: "#555" }}
                  ></i>
                  <small style={{ fontSize: 10 }} className="text-center">
                    {t(menu.name)}
                  </small>
                </a>
              </div>
            ))}
            <div className="flex items-center justify-center flex-1" />
          </div>
        </div>
      )}
    </>
  );
};
