import { useTranslation } from "react-i18next";
import InfoMessage from "../InfoMessage";
import CustomButton from "../CustomButton";

const CustomMessageComponent = () => {
  const { t } = useTranslation();
  return (
    <div>
      {t("business_signup_description_1")}
      <b>{t("business_signup_description_2")}</b>
      {t("business_signup_description_3")}
    </div>
  );
};

const UsertypeSelection = (props: any) => {
  const {
    typeOfUser,
    usertype,
    processing,
    onNext,
    handleUserType,
    setdiscoverySubStep,
    setIsSoleProprietorship,
    isSoleProprietorship,
    setconfirmationModalOpen,
  } = props;
  const { t } = useTranslation();

  return (
    <div className="my-8 flex-center usertypeselectionmodal">
      <div className="max-w-[600px] max-sm:mt-24 mx-auto pb-4 mb-5 p-4 max-sm:px-4 bg-white rounded-lg shadow-c">
        <div className="font-bold text-center text-2xl mb-2">
          {t("Account_Type")}
        </div>
        <div className="mb-6 text-center opacity-75">
          {t("select_account_type")}
        </div>

        {/* user type selection */}
        <div className="mb-7 flex flex-col gap-2 p-2">
          {typeOfUser.map((usertype: any, index: any) => {
            return (
              <div
                key={index}
                className={`flex flex-col items-center justify-center gap-1 rounded-lg min-h-[56px] py-2 pr-3 pl-4 shadow-c${
                  usertype.active ? " border-1 border-[#037375]" : ""
                }`}
                onClick={() => {
                  handleUserType(usertype.title);
                }}
              >
                <div className="flex w-full items-center">
                  <img
                    src={`./images/${usertype.image}.svg`}
                    style={{ height: 39, width: 45 }}
                  />
                  <label className="md:text-md max-sm:!text-sm">
                    {t(usertype?.title)}
                  </label>
                </div>

                {index == 1 && (
                  <>
                    <div className={`m-0 flex w-full pl-4 my-2`}>
                      <div className="flex-center mr-4">
                        <input
                          type="checkbox"
                          className={"my-auto withdrawcheckbox"}
                          onChange={(e) => {
                            setIsSoleProprietorship(e.target.checked);
                          }}
                          name="Mobile Money"
                          checked={isSoleProprietorship}
                        />
                      </div>
                      <div className={`text-lg font-medium opacity-75`}>
                        {t("enterpreneur_label")}
                      </div>
                    </div>

                    <InfoMessage
                      CustomIcon={
                        <img
                          src={`./images/info.svg`}
                          style={{ height: 30, minWidth: 30 }}
                        />
                      }
                      customMessageComponent={<CustomMessageComponent />}
                      message={"business_signup_description"}
                      iconWidth={30}
                      iconHeight={30}
                      textClasses="pr-3 text-sm pl-2 text-[#012938] opacity-75"
                      mainContainerClasses="bg-[#DEEEFA] rounded-xl p-1 flex mb-2"
                    />
                  </>
                )}
              </div>
            );
          })}
        </div>

        <CustomButton
          classNames="min-h-[48px] flex-1 rounded-xl text-white capitalize text-sm font-bold bg-black w-full"
          label="next"
          onClick={() =>
            usertype == "individual" ? onNext() : setconfirmationModalOpen(true)
          }
          disabled={processing}
        />
      </div>
    </div>
  );
};

export default UsertypeSelection;
