import { useTranslation } from "react-i18next";
import CustomInputWithLabel from "../CustomInputWithLabel";

const UserSummary = (props: any) => {
  const {
    userBalance,
    amountInEuros,
    setAmountInEuros,
    disabled = false,
  } = props;
  const { t } = useTranslation();

  const withdrawAllAmount = () => {
    setAmountInEuros(userBalance);
  };
  return (
    <>
      <CustomInputWithLabel
        disabled={disabled}
        mainText={amountInEuros}
        hasSideLabel={true}
        sideLabelText={"EUR"}
        label="amount_to_withdraw"
        mainTextClasses="text-2xl bg-transparent font-bold text-[rgba(0,0,0,0.75)] w-[80%]"
        mainContainerClasses="justify-center rounded-xl mt-4 flex flex-col min-h-[60px] border-1-[rgba(0, 0, 0, 0.2)] border"
        onChange={(e: any) => setAmountInEuros(e.target.value)}
      />

      <div className="flex justify-between px-0 gap-3">
        <div className="px-0">
          {t("you_have_balance_1")}
          <span className="text-[#037375] font-bold">{userBalance}€</span>
          {t("you_have_balance_2")}
        </div>
        <div
          className="text-[#037375] font-bold text-right cursor-pointer"
          onClick={withdrawAllAmount}
        >
          {t("click_to_withdraw")}
        </div>
      </div>
    </>
  );
};

export default UserSummary;
