import { Form, Formik } from "formik";
import CustomDropdown from "../CustomDropdown";
import InputField from "../forms/InputField";
import FormErrorText from "../FormErrorText";
import { Alert } from "@material-ui/lab";
import { setSignUpData } from "../../store/features/Auth/AuthSlice";
import * as Sentry from "@sentry/browser";
import {
  addUser,
  saveRegistrationProgress,
} from "../../store/features/Auth/Auth";
import { toast } from "material-react-toastify";
import { isEmpty } from "../../utilities/help";
import InfoMessage from "../InfoMessage";
import { useTranslation } from "react-i18next";
import { KeyboardArrowDown } from "@material-ui/icons";
import CustomButton from "../CustomButton";
import CustomInputField from "./CustomInputField";

const CustomMessageComponent = () => {
  const { t } = useTranslation();
  return (
    <div>
      {t("income_note_1")}
      <b>{t("income_note_2")}</b>
      {t("income_note_3")}
      <b>{t("income_note_4")}</b>
    </div>
  );
};

const IndividualInfoScreen = (props: any) => {
  const {
    progressBar,
    signUpData,
    user,
    jobListing,
    t,
    dispatch,
    Yup,
    setActiveStep,
    errorsState,
    setprogressBarData,
  } = props;

  const revenueOptions = [
    {
      id: 1,
      name: "0 - 1400€",
    },
    {
      id: 2,
      name: "1400€ - 2500€",
    },
    {
      id: 3,
      name: "2500€ - 4000€",
    },
    {
      id: 4,
      name: "> 4000€",
    },
  ];

  return (
    <div className="flex flex-col justify-center px-4 py-7 mb-5 max-sm:px-4 max-sm:py-8 w-full bg-white rounded-xl shadow-c">
      <div className="font-bold text-center text-2xl">
        {t("income_details")}
      </div>
      <div className="opacity-75 text-center mb-3">{t("enter_info")}</div>

      <InfoMessage
        iconContainerClasses="flex flex-0 items-center"
        CustomIcon={
          <img src={`./images/info.svg`} style={{ height: 30, minWidth: 30 }} />
        }
        iconWidth={30}
        iconHeight={30}
        textClasses="pr-3 text-sm pl-2 text-[#012938] opacity-75"
        mainContainerClasses="bg-[#DEEEFA] rounded-xl p-1 flex mb-4"
        customMessageComponent={<CustomMessageComponent />}
      />

      <Formik
        validateOnMount
        key="form5"
        initialValues={{
          activity_id: signUpData?.job
            ? ""
            : signUpData?.activity_id ||
              user?.activity_id ||
              user?.progression?.activity_id ||
              "",
          job:
            signUpData?.job ||
            user?.job ||
            user?.progression?.job ||
            "Autres activités",
          monthly_revenue:
            signUpData?.monthly_revenue ||
            user?.monthly_revenue ||
            user?.progression?.monthly_revenue ||
            "",
          customJob: "",
        }}
        onSubmit={(data, { setSubmitting }) => {
          try {
            // activity id in case on non custom job
            const activityId: any = jobListing.find(
              (job: any) => job.name == data.job
            );

            let payload = {
              ...signUpData,
              ...data,
              job: data.job == "Autres activités" ? data.customJob : data.job,
              monthly_revenue: data.monthly_revenue,
              reason_for_modification: "Adding revenue details",
            };
            delete payload.customJob;

            let registrationPayload: any = {
              ...user?.progression,
              ...signUpData,
              ...payload,
              usertype: "individual",
              step: 2,
            };

            if (!isEmpty(payload?.job)) {
              payload.job =
                data.job == "Autres activités" ? data.customJob : data.job;
              delete payload.activity_id;
              delete registrationPayload.activity_id;
            } else if (!isEmpty(payload?.activity_id)) {
              delete payload.job;
              delete registrationPayload.job;
            }

            dispatch(setSignUpData(payload));
            saveRegistrationProgress({
              ...registrationPayload,
            }).finally(() => {
              let verificationPayload = {
                ...signUpData,
                ...user?.progression,
              };

              addUser(
                {
                  email: verificationPayload.email,
                  phone_number: verificationPayload.phone_number,
                  country_code: verificationPayload.country_code,
                  first_name: verificationPayload.first_name,
                  last_name: verificationPayload.last_name,
                  city: verificationPayload.city,
                  post_code: verificationPayload.post_code,
                  countryName: verificationPayload.countryName,
                  address_line: verificationPayload.address_line,
                  user_origin: verificationPayload.user_origin,
                  is_sole_proprietorship:
                    verificationPayload.is_sole_proprietorship,
                  ...payload,
                  usertype: "individual",
                  step: 2,
                },
                "individual"
              )
                .then(() => {
                  window.heap.track("Revenue information set", {
                    ...data,
                    email: user?.email,
                  });

                  setActiveStep("individualTransferInfo");
                  setprogressBarData({
                    progress: 60,
                    title: "receiving_countries",
                  });
                })
                .catch((err) => {
                  toast.error(t(err?.data?.message));
                  if (err?.data?.errors) {
                    try {
                      Object.keys(err?.data?.errors).forEach((error: any) => {
                        toast.error(`${t(error)}${t("is_invalid")}`);
                      });
                    } catch (error) {
                      Sentry.captureException(error);
                    }
                  }
                })
                .finally(() => {
                  setSubmitting(false);
                });
            });
          } catch (error: any) {
            Sentry.captureException(error);
          }
        }}
        validationSchema={Yup.object().shape({
          job: Yup.string().when(["customJob", "activity_id"], {
            is: (customJob: any, activity_id: any) => {
              return (
                (customJob == "" && activity_id == "") ||
                (customJob == null && activity_id == null) ||
                (customJob == undefined && activity_id == undefined)
              );
            },
            then: Yup.string().required("job_err"),
          }),
          customJob: Yup.string().test(
            "yourTestCondition",
            "job_err",
            function (value: any, context: any) {
              return (
                (((context.parent.job !== "" &&
                  context.parent.job !== undefined) ||
                  (context.parent.activity_id !== "" &&
                    context.parent.activity_id !== undefined)) &&
                  context.parent.job !== "Autres activités") ||
                (context.parent.job == "Autres activités" &&
                  value !== "" &&
                  value !== undefined)
              );
            }
          ),
          monthly_revenue: Yup.string().required("revenue_err"),
        })}
      >
        {({
          values,
          handleChange,
          handleBlur,
          errors,
          isSubmitting,
          touched,
          isValid,
          setFieldValue,
        }) => (
          <Form className="company-registration">
            <div className="mb-2">{t("individual_specify_section")}</div>

            <CustomDropdown
              CustomIcon={
                <KeyboardArrowDown
                  style={{ width: "18px", height: "21px", color: "black" }}
                />
              }
              otherValue={"Autres activités"}
              defaultValue={
                jobListing.find((job: any) => job.id == values?.activity_id)
                  ?.name
                  ? jobListing.find((job: any) => job.id == values?.activity_id)
                      ?.name
                  : values.job
              }
              placeholderClasses="opacity-50 font-normal"
              placeholder={t("job_select")}
              customClasses="!rounded-xl h-[50px] w-full border-1 mb-2 pl-5 pr-2 border-[rgba(0,0,0,0.2)]"
              list={jobListing}
              setCustomProvider={(val: any) =>
                val !== "" && handleChange("customJob")(signUpData?.job)
              }
              onchange={(val: any) => {
                try {
                  if (val == "Autres activités") {
                    setFieldValue("activity_id", "");
                    setFieldValue("job", val);
                  } else {
                    const activityId: any = jobListing.find(
                      (job: any) => job.name == val
                    );
                    setFieldValue("activity_id", activityId?.id);
                    setFieldValue("job", "");
                    setFieldValue("customJob", "");
                  }
                } catch (error) {
                  Sentry.captureException(error);
                }
              }}
            />

            {values.job == "Autres activités" && (
              <CustomInputField
                mainContainerClasses="w-full mb-2"
                name="customJob"
                type="text"
                placeholder={t("OtherActivities")}
                handleChange={handleChange}
                value={values.customJob}
                error={t(`${errors.customJob}`)}
                touched={touched.customJob}
                onBlur={handleBlur}
              />
            )}

            <div className="mb-2">{t("individual_income")}</div>

            <div className="mb-4">
              <CustomDropdown
                placeholder={t("revenue_select")}
                otherValue={
                  revenueOptions.length > 0 && revenueOptions[0]?.name
                }
                defaultValue={values?.monthly_revenue}
                list={revenueOptions}
                onchange={(val: any) => {
                  handleChange("monthly_revenue")(val);
                }}
                CustomIcon={
                  <KeyboardArrowDown
                    style={{ width: "18px", height: "21px", color: "black" }}
                  />
                }
                placeholderClasses="opacity-50 font-normal"
                customClasses="!rounded-xl h-[50px] w-full border-1 pl-5 pr-2 border-[rgba(0,0,0,0.2)]"
              />

              {errors?.monthly_revenue && !isSubmitting && (
                <FormErrorText
                  fontSize={12}
                  classNames="!m-0 text-xs"
                  errorMessage={errors?.monthly_revenue}
                />
              )}
            </div>

            <div>
              {errorsState.length > 0 && (
                <div className="mb-4">
                  <Alert severity="error">{errorsState.join(" ,")}</Alert>
                </div>
              )}
            </div>
            <div className="flex justify-between gap-3">
              <CustomButton
                label="back"
                onClick={() => {
                  setActiveStep(1);
                  setprogressBarData({
                    progress: 20,
                    title: "personal_information",
                  });
                }}
              />
              <CustomButton
                classNames="min-h-[48px] flex-1 rounded-xl text-white capitalize text-sm font-bold bg-black"
                label="next"
                type="submit"
                disabled={isSubmitting || !isValid}
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default IndividualInfoScreen;
