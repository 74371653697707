import { useTranslation } from "react-i18next";

const WarningMessage = (props: any) => {
  const {
    message = "",
    mainMessage = "",
    underlineMessage,
    underlineMessageOnclick = () => {},
    extraClasses = null,
    customMessageComponent = null,
    mainContainerClasses = "border mt-4 !border-[#2196F3] p-2 pr-4 rounded flex items-center flex-col",
    textClasses = "text-base font-bold",
    smallTextClasses = "px-2 pb-3",
    iconWidth = 40,
    iconHeight = 40,
    CustomIcon = null,
    iconContainerClasses = "flex flex-0",
    messageClasses,
  } = props;
  const { t } = useTranslation();
  return (
    <div
      className={
        extraClasses
          ? `${mainContainerClasses} ${extraClasses}`
          : mainContainerClasses
      }
    >
      <div className="flex w-full justify-start items-center">
        <div className={iconContainerClasses}>
          {CustomIcon ? (
            CustomIcon
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={iconWidth}
              height={iconHeight}
              className="px-2"
              fill="#F5A05B"
              viewBox="0 0 256 256"
            >
              <path d="M236.8,188.09,149.35,36.22h0a24.76,24.76,0,0,0-42.7,0L19.2,188.09a23.51,23.51,0,0,0,0,23.72A24.35,24.35,0,0,0,40.55,224h174.9a24.35,24.35,0,0,0,21.33-12.19A23.51,23.51,0,0,0,236.8,188.09ZM222.93,203.8a8.5,8.5,0,0,1-7.48,4.2H40.55a8.5,8.5,0,0,1-7.48-4.2,7.59,7.59,0,0,1,0-7.72L120.52,44.21a8.75,8.75,0,0,1,15,0l87.45,151.87A7.59,7.59,0,0,1,222.93,203.8ZM120,144V104a8,8,0,0,1,16,0v40a8,8,0,0,1-16,0Zm20,36a12,12,0,1,1-12-12A12,12,0,0,1,140,180Z"></path>
            </svg>
          )}
        </div>
        <div className={textClasses}>
          {customMessageComponent ? (
            customMessageComponent
          ) : (
            <>{mainMessage && t(mainMessage)}</>
          )}
        </div>
      </div>
      <div
        className={
          messageClasses
            ? `${messageClasses} ${smallTextClasses}`
            : smallTextClasses
        }
      >
        {customMessageComponent ? (
          customMessageComponent
        ) : (
          <>
            {message && t(message)}
            {underlineMessage && (
              <b onClick={underlineMessageOnclick}>{t(underlineMessage)}</b>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default WarningMessage;
