import { useTranslation } from "react-i18next";

const CustomInputWithLabel = (props: any) => {
  const {
    labelClasses = null,
    label = "",
    mainContainerClasses = null,
    mainTextClasses = null,
    hasSideLabel = false,
    sideLabelClasses = null,
    sideLabelText = "",
    mainText = "",
    isEditable = false,
    disabled = false,
    onChange = () => {},
  } = props;
  const { t } = useTranslation();
  return (
    <div
      className={mainContainerClasses || `p-2 rounded-lg flex flex-col`}
      style={{ opacity: disabled ? 0.75 : 1 }}
    >
      <div className={labelClasses || "text-xs text-[rgba(0,0,0,0.5)]"}>
        {t(label)}
      </div>
      <div className="flex justify-between items-center">
        {/* <div className={mainTextClasses || "text-xl"}>{t(mainText)}</div> */}
        <input
          type="text"
          value={mainText}
          onChange={onChange}
          className={mainTextClasses || "text-xl"}
          disabled={disabled}
        />
        {hasSideLabel && (
          <div className={sideLabelClasses || "text-[rgba(0,0,0,0.75)]"}>
            {t(sideLabelText)}
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomInputWithLabel;
