import moment from "moment";
import { buildString, convertUTCDateToLocalDate } from "../../utilities/help";
import { AccessTime, Check, Close } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { useEffect, useMemo, useState } from "react";
import * as Sentry from "@sentry/browser";
import { useAppSelector } from "../../store/hooks";

const FundReceived = (props: any) => {
  const { body, isSender, fetchData } = props;
  const { t } = useTranslation();
  const {
    auth: { lang },
  } = useAppSelector((state) => state.persistedReducer);

  if (body?.message_en) {
    return <>{lang == "en" ? body?.message_en : body?.message_fr}</>;
  } else {
    return <></>;
  }
};

const StatusIcon = (props: any) => {
  const { datetime, status } = props;

  if (
    datetime == null &&
    status != "payment_aborted" &&
    status != "payment_failed"
  ) {
    return <AccessTime className="opacity-50 text-black" />;
  } else if (
    datetime != null &&
    (status == "payment_aborted" || status == "payment_failed")
  ) {
    return (
      <Close
        className="text-[#DE4007]"
        style={{
          height: 25,
          width: 25,
        }}
      />
    );
  } else {
    return (
      <Check
        className="text-[#037375] bg-white font-[20px]"
        style={{
          height: 25,
          width: 25,
        }}
      />
    );
  }
};

const TransactionUpdate = (props: any) => {
  const { fetchData, t, isSender, transferCompleted } = props;
  const {
    auth: { lang },
  } = useAppSelector((state) => state.persistedReducer);

  const [statusListing, setstatusListing] = useState([]);

  // generate correct listing
  useEffect(() => {
    try {
      if (fetchData?.statuses && fetchData?.statuses?.length > 0) {
        const operationType = fetchData?.operation_type?.toLowerCase();
        const operationStatus = fetchData?.operation_status_en?.toLowerCase();

        const listing = fetchData?.statuses.filter((statusObj: any) => {
          if (
            operationType == "direct transfer" ||
            operationType == "transfer"
          ) {
            const isPaymentAborted = operationStatus == "payment aborted";
            const isPaymentFailed = operationStatus == "failed";

            if (
              (isPaymentAborted &&
                (statusObj.status == "payment_failed" ||
                  statusObj.status == "payment_completed")) ||
              (isPaymentFailed &&
                (statusObj.status == "payment_aborted" ||
                  statusObj.status == "payment_completed"))
            ) {
              return false;
            } else if (
              !isPaymentAborted &&
              !isPaymentFailed &&
              (statusObj.status == "payment_failed" ||
                statusObj.status == "payment_aborted")
            ) {
              return false;
            } else {
              return true;
            }
          } else {
            return true;
          }
        });
        setstatusListing(listing);
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  }, [fetchData]);

  return (
    <div className="transactionUpdate px-4 flex flex-col justify-between">
      <div>
        <div className="font-bold mb-4">{t("transaction_status")}</div>

        {statusListing.map((body: any, bodyIndex: any) => (
          <div
            className="flex flex-row items-start pb-4 relative gap-2 min-w-[272px]"
            key={bodyIndex}
          >
            <div className="relative w-[40px]">
              <StatusIcon datetime={body?.datetime} status={body?.status} />
              {bodyIndex !== statusListing.length - 1 && (
                <div
                  className="border-black opacity-25 border-l-2 absolute w-5 h-5"
                  style={{
                    left: 11,
                    bottom: -29,
                  }}
                ></div>
              )}
            </div>

            <div className="text-sm w-full">
              {/* status time and date */}
              <div
                className={`${
                  body?.status == "payment_aborted" ||
                  body?.status == "payment_failed"
                    ? "text-[#C42704]"
                    : body?.datetime == null
                    ? "text-[rgba(0,0,0,0.75)]"
                    : "text-[#037375]"
                } font-medium`}
              >
                {body?.datetime == null
                  ? t("on_hold")
                  : lang == "en"
                  ? moment(convertUTCDateToLocalDate(new Date(body?.datetime)))
                      .locale("de")
                      .format("dddd MMM DD [at] hh:mm A")
                  : `${t(
                      moment(
                        convertUTCDateToLocalDate(new Date(body?.datetime))
                      )
                        .locale("de")
                        .format("dddd")
                    )}  ${moment(
                      convertUTCDateToLocalDate(new Date(body?.datetime))
                    )
                      .locale("de")
                      .format("DD")} ${t(
                      moment(
                        convertUTCDateToLocalDate(new Date(body?.datetime))
                      )
                        .locale("de")
                        .format("MMM")
                    )} ${t("at")} ${moment(
                      convertUTCDateToLocalDate(new Date(body?.datetime))
                    )
                      .locale("de")
                      .format("hh:mm A")}`}
              </div>

              <div className="opacity-75 text-black">
                <FundReceived
                  body={body}
                  isSender={isSender}
                  fetchData={fetchData}
                />
              </div>
              {transferCompleted && bodyIndex == statusListing.length - 1 && (
                <b className="text-base !text-black block !opacity-75 mt-3">
                  {fetchData?.operation_direction?.toLowerCase() == "sending"
                    ? t("transfer_completed_successfully")
                    : t("transfer_success_message_receiver")}
                </b>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TransactionUpdate;
