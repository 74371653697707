import InputField from "../forms/InputField";
import FileUpload from "../FileUpload";
import { toast } from "material-react-toastify";
import {
  delete_uploaded_files,
  download_file,
  upload_transaction_docs,
} from "../../store/features/Auth/Auth";
import * as Sentry from "@sentry/browser";
import InfoMessage from "../InfoMessage";
import { confirmAlert } from "react-confirm-alert";
import { useMemo, useState } from "react";
import { performPDFDownload } from "../../utilities/help";
import { CircularProgress } from "@material-ui/core";

const AddDocumentModal = (props: any) => {
  const {
    setIsOpen,
    uploading,
    t,
    setTitle,
    title,
    fetchData,
    setUploading,
    getOperationMetaData,
    isSender,
    isDisabled,
  } = props;
  const [downloading, setDownloading] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const upload = (event: any) => {
    try {
      const files: any = event;
      if (files.length === 0) {
        toast.error(t("Please select a file"));
        return;
      }
      if (title === "") {
        toast.error(t("Please enter the document title/name"));
        return;
      }

      const { name, type, size } = files[0];

      if (size < 5000000) {
        const formData = new FormData();
        const ext = name.split(".");
        formData.append("file", files[0]);
        formData.append("name", title);
        setUploading(true);

        upload_transaction_docs(formData, fetchData.payment_id)
          .then((res) => {
            setUploading(false);
            setIsOpen(false);
            getOperationMetaData();
            setTitle("");
          })
          .catch((error: any) => {
            toast.error(error?.data?.message);
            setUploading(false);
            setIsOpen(false);
            toast.warning(t(error?.data?.message));
            Sentry.captureException(error);
          });
      } else {
        setUploading(false);
        toast.error(t("file_to_large"));
      }
    } catch (error: any) {
      setUploading(false);
      Sentry.captureException(error);
    }
  };

  const Loading = () => {
    return (
      <div className="flex flex-col items-center justify-center p-20 h-full">
        <p className="text-2xl">
          <b>{t("Uploading")}</b>
        </p>
        <p>
          <small>{t("Please_wait")}</small>
        </p>
      </div>
    );
  };
  const isDeleteDisabled = useMemo(
    () => ["bulk_transfer"].includes(fetchData?.operation_type),
    [fetchData?.operation_type]
  );

  const deleteFile = (row: any) => {
    if (isDeleteDisabled) {
      return;
    }

    confirmAlert({
      title: t("deleting_head_text"),
      message: t("deleting_body_text"),
      overlayClassName: "zIndexClass",
      closeOnEscape: true,
      closeOnClickOutside: true,
      buttons: [
        {
          label: t("yes"),
          onClick: () => {
            setDeleting(true);
            delete_uploaded_files(fetchData.transfer.id, row.id)
              .then((response: any) => {
                setDeleting(false);
                toast.success("Document was deleted successfully");
                getOperationMetaData();
              })
              .catch((error: any) => {
                toast.error(error?.data?.message);
                setDeleting(false);
                toast.warning(t(error?.data?.message));
                Sentry.captureException(error);
              });
          },
        },
        {
          label: t("no"),
          onClick: () => {},
        },
      ],
    });
  };

  const get_document = async (row: any) => {
    try {
      setDownloading(true);
      const result = await download_file(row.id);
      performPDFDownload(result, row.id);
      toast.success("Download is complete");
      setDownloading(false);
    } catch (error: any) {
      toast.error(error?.data?.message || error.message);
      setDownloading(false);
    }
  };

  const DeleteIcon = (props: any) => {
    const { row } = props;
    return (
      <>
        {deleting ? (
          <CircularProgress size={14} />
        ) : (
          <img
            src={`../documentDelete.svg`}
            style={{
              height: 15,
              width: 15,
            }}
            onClick={() => deleteFile(row)}
            className={`inline-block ${
              isDeleteDisabled ? " cursor-not-allowed opacity-50" : ""
            }`}
            alt=""
          />
        )}
      </>
    );
  };

  const DownloadIcon = (props: any) => {
    const { row } = props;
    return (
      <>
        {downloading ? (
          <CircularProgress size={14} className="mr-2" />
        ) : (
          <img
            src={`../documentDownload.svg`}
            style={{
              height: 15,
              width: 15,
            }}
            onClick={() => get_document(row)}
            className="inline-block mr-2"
            alt=""
          />
        )}
      </>
    );
  };

  return (
    <div className="details px-4 col-md-12">
      {uploading ? (
        <Loading />
      ) : (
        <div className="h-full">
          <InputField
            name="title"
            extraClasses="customInputStyle"
            handleChange={(text: any) => setTitle(text.target.value)}
            value={title}
            label={t("file_title") + " *"}
          />

          <FileUpload
            onDrop={(acceptedFiles: any) => {
              upload(acceptedFiles);
            }}
            multiple={false}
          >
            <label className="flex font-medium text-gray-400 justify-center p-10 transition bg-white border-2 border-gray-300 border-dashed rounded-md appearance-none cursor-pointer hover:border-gray-400 focus:outline-none">
              {t("upload_file_placeholder")}
            </label>
          </FileUpload>

          {fetchData?.transfer?.documents && isSender && (
            <div className="my-4">
              {fetchData?.transfer?.documents?.map(
                (row: any, index: number) => (
                  <div
                    key={index + row?.name}
                    className="flex justify-between items-center"
                  >
                    <div>
                      <img
                        src={`../documentIcon.svg`}
                        style={{
                          height: 30,
                          width: 30,
                        }}
                        className="inline-block mr-8"
                        alt=""
                      />
                      <span>{row?.name}</span>
                    </div>
                    <div className="flex justify-between items-center">
                      <DownloadIcon row={row} />
                      <DeleteIcon row={row} />
                    </div>
                  </div>
                )
              )}
            </div>
          )}

          <InfoMessage message={"document_upload_message"} />
        </div>
      )}
    </div>
  );
};

export default AddDocumentModal;
