export const TRANSLATIONS_ES = {
  welcome: "Bienvenue sur Danapay",
  version: "Version",
  thanks_for_application:
    "Merci! Nous traiterons votre inscription et vous recevrez un e-mail de confirmation dans les 24h.",
  linkExpired: "Le lien de vérification de votre compte a expiré.",
  serviceWarning:
    "Notre service est actuellement interrompu. Veuillez contacter le support.",
  frequired: "Veuillez remplir les champs requis.",
  emailExists: "Cet e-mail existe déjà. Veuillez vous connecter.",
  accountActivation:
    "Votre compte est en cours d'activation. Cela prendra 24 heures maximum. Veuillez vous reconnecter dès que vous recevez l'e-mail de confirmation.",
  sendRange: "Vous pouvez envoyer entre X et Y.",
  escrowText:
    "Le paiement avec séquestre apporte plus de confiance lors de vos opérations. Vos fonds seront bloqués et votre destinataire pourra voir qu'ils sont disponibles mais, il ne pourra pas les retirer tant qu'il n'aura envoyé le produit ou réalisé la prestation'. Vous ne pourrez pas non plus les retirer tant que votre destinataire n'aura pas accepté le remboursement.",
  instantText: "Réception des fonds en 24/48h.",
  payConfirmation:
    "Paiement accepté. Nous allons maintenant exécuter votre transfert.",
  payCancelled:
    "Paiement annulé ou refusé. Si vous ne l'avez pas annulé, veuillez essayer avec un autre compte bancaire.",
  adminErrorText: "Une erreur s'est produite, veuillez contacter le support.",
  forgotPass: "Mot de passe oublié",
  fptext: "Entrez l'email que vous avez utilisé lors de votre inscription",
  termsText:
    "En cliquant sur Soumettre, vous acceptez notre politique de confidentialité applicable au traitement de vos données personnelles.",
  sutitile: "Inscrivez-vous à Danapay!",
  suSubtitle:
    "Inscrivez-vous et découvrez la nouvelle expérience de paiement local et international",
  email: "Adresse e-mail",
  password: "Mot de passe",
  changePass: "changez votre mot de passe",
  changePassSub:
    "choisissez un nouveau mot de passe pour sécuriser votre compte",
  confirmPass: "Confirmez le mot de passe",
  newPass: "Nouveau mot de passe",
  oldPass: "Ancien mot de passe",
  register: "S'inscrire",
  Change_Password: "Changer le mot de passe",
  ftHelp: "Aide",
  ftTerms: "Conditions générales",
  ftPolicy: "Politique de confidentialité",
  loginText: "Connectez-vous et commencez vos paiements",
  login: "connecter",
  start_Transfer: "Démarrer le transfert",
  beneficiary: "Bénéficiaire",
  amount: "Montant",
  payment: "Paiement",
  confirmation: "Confirmation",
  close: "Fermer",
  back: "Retour",
  next: "Suivant",
  fees: "Frais",
  exchange_rate: "Taux de change",
  Payout_mode: "Mode de retrait",
  transaction_type: "Type de transaction",
  instant_payments: "Paiement instantané",
  escrow_payment: "Paiement avec séquestre",
  escrowed: "Mise en séquestre",
  enterPerson: "Entrez vos informations personnelles",
  validPhone: "Un numéro de téléphone valide est requis.",
  finish: "Finaliser",
  coInfo: "Saisissez les informations de votre entreprise",
  docsTitle: "Documents de l'entreprise",
  docsReason:
    "Nous avons besoin de ces documents pour des raisons de conformité. Ils vous sont demandés une seule fois lors de votre inscription.",
  docsDrag:
    "Glissez et déposez vos fichiers ici, ou cliquez pour sélectionner des fichiers",
  docsDropping: "Déposez les fichiers ici...",
  skip: "Passer",
  continue: "Continuez",
  total: "Total",
  action: "Actions",
  date: "Date",
  photo: "Photo",
  status: "Statut",
  instant: "Paiement instantané",
  escrow: "Paiements avec séquestre",
  home: "Accueil",
  developer: "Développeur",
  eur_amount: "Montant en EUR",
  cfa_amount: "Montant en CFA",
  payOutMode: "Mode de paiement",
  transactionType: "Type de transaction",
  wellDone: "Bien joué!",
  successMessage:
    "Votre paiement a été pris en compte. Dès que nous aurons reçu les fonds, vous en serez informé et nous exécuterons le transfert",
  backHome: "Retour à l'accueil",
  successFooter: "Créé avec amour par Danapay pour une meilleure Afrique!",
  createApp: "Créer une nouvelle application",
  appId: "App Id",
  appIcon: "App Icon",
  apiKey: "API Key",
  sandBox: "Sand Box",
  production: "Production",
  AppGenInfo: "Informations générales sur l'application",
  appName: "Nom de l'application",
  application: "Description de l'application",
  general: "Général",
  name: "Nom",
  Email: "E-mail",
  companyNumber: "Numéro de l'entreprise",
  Verifying_your_Email: "Vérification de votre e-mail",
  Please_wait: "Veuillez patienter s'il vous plais",
  email_validated: "Votre e-mail a été vérifié",
  login_here: "Connectez-vous ici",
  EMail_Verification: "Vérification de l'e-mail",
  sent_code: "Nous vous avons envoyé un e-mail de confirmation",
  click_to_continue:
    "Cliquez sur le lien reçu pour continuer votre inscription",
  Send_New_Link: "Envoyer un nouveau lien",
  Email_Address: "Adresse e-mail",
  FirstName: "Prénom",
  LastName: "Nom de famille",
  OtherActivities: "Autres activités",
  CompanyName: "Nom de la compagnie",
  RegistrationID: "Numéro d'immatriculation",
  AddressInformation: "Informations sur l'adresse",
  Country: "Pays",
  City: "Ville",
  IBAN: "IBAN",
  Register: "S'inscrire",
  link_expired: "Ce lien de vérification a expiré",
  askNewLink:
    "Demandez un nouveau lien pour continuer votre inscription ou recommencez.",
  RequestLink: "Demander un lien",
  acceptTerms:
    "En cliquant sur Soumettre, vous acceptez notre Politique de confidentialité applicable au traitement de vos données personnelles.",
  AddApplication: "Ajouter une application",
  Date_Created: "Date de création",
  newApp: "Créer une nouvelle application.",
  SandBox: "Sand Box",
  App_General_Information: "Informations générales sur l'application",
  Application_Name: "Nom de l'application",
  Application_Description: "Description de l'application",
  Market_Place: "Marché",
  Market_Place_Desc: "Vos vendeurs seront payés par Danapay",
  Merchant: "Marchand (à venir)",
  Merchant_Desc: "Vos vendeurs seront payés par Danapay",
  API_Scope: "Périmètre de l'API ",
  Configuration: "Configuration",
  Redirect_URL: "URL de redirection",
  Webhook: "Webhook",
  Creating_App: "Création d'une application",
  AppInfoDesc:
    "Les app_secret et app_private_key doivent être stockés en toute sécurité dans votre environnement backend, c'est-à-dire ni dans un bundle côté client ni dans une application front-end.",
  AppInfo: "Informations sur l'application",
  App_Secret: "App Secret",
  Download_Private_Key: "Télécharger la clé privée",
  App_Icon: "Icône de l'application",
  App_Name: "Nom de l'application",
  Api_Key: "Api Key",
  App_ID: "App_ID",
  Amount_received_CFA: "Montant en CFA",
  Amount_received_EUR: "Montant en EUR",
  Processing: "Traitement en cours",
  fetchingTransfer: "Récupération de la transaction",
  Repeating_Transfer: "Répéter le transfert",
  Back_To_website: "Retourner vers le site marchand",
  Available_outstanding_balance: "Solde disponible ",
  Withdraw_All: "Tout retirer",
  expectAmount: "Le montant saisi doit être de 1 à ",
  Add_New_Account: "Ajouter un nouveau compte",
  Bank_Name: "Nom de banque",
  Owner: "Propriétaire",
  sendingOTP: "Nous enverrons un code secret à",
  Send_and_Continue: "Envoyer et continuer",
  sentOTP: "Nous vous avons envoyé un code secret à",
  enterOTP: "Entrez-le ci-dessous pour confirmer le retrait",
  EnterOTP: "Saisissez le code secret",
  ConfirmCashout: "Confirmer le retrait",
  onTheWay: "En cours...",
  onTheWayDesc:
    "Votre retrait a été pris en compte et est en cours de traitement. Vous serez informé dès que nous aurons finalisé la transaction.",
  Havegreatday: "Passez une bonne journée!",
  GOBACK: "Accueil",
  Title: "Titre",
  OwnerName: "Nom titulaire",
  Add: "Ajouter",
  CompleteTransfer: "Transfert exécuté",
  WellDone: "Bien joué!",
  WellDoneDesc: "Effectuer un dépôt sur notre compte à la Banque d'Afrique",
  enterPhoneNumber: " Entrez le numéro de téléphone du bénéficiaire",
  beneInfo: "Veuillez saisir les informations sur le bénéficiaire.",
  AllTransactions: "Toutes vos transactions",
  Source: "Origine",
  Actions: "Actions",
  Status: "Statut",
  Amount: "Montant",
  Date: "Date",
  Name: "Nom",
  Photo: "Photo",
  Type: "Type",
  RefreshingList: "Mise à jour...",
  FetchingPayment: "Récupération des paiements",
  Release: "Débloquer et transférer",
  AskRefund: "Demander un remboursement",
  Repeat: "Répéter",
  RevokeRelease: "Annuler le déblocage",
  Pay: "Payer",
  Refund: "Rembourser",
  RevokeRefund: "Annuler le remboursement",
  Sender: "Expéditeur",
  CompanyRegistrationNo: "Numéro d'immatriculation",
  Receiver: "Bénéficiaire",
  Details: "Détails",
  Payment_method: "Mode de paiement",
  Sent_amount: "Montant envoyé",
  Fees: "Frais",
  Exchange_rate: "Taux de change",
  Received_amount: "Montant reçu",
  AskRelease: "Demander le déblocage",
  InstantPayment: "Paiement instantané",
  Escrowed_Payments: "Paiements avec séquestre",
  Withdraws: "Retrait",
  StartTransfer: "Démarrer le transfert",
  Enter_Withdraw_details: "Entrez les détails du retrait",
  Information: "Information",
  InformationDesc:
    "Votre entreprise est actuellement inactive. Veuillez contacter le support pour plus de détails.",
  Address: "Adresse",
  NotReceiver:
    "Veuillez contacter le support si ce n'est pas votre destinataire",
  BankDetails: "Coordonnées bancaires",
  Confirmation: "Confirmation",
  Complete: "Executer",
  TransactionDetails: "Détails de la transaction",
  sentOtp:
    "Nous avons envoyé un code secret sur votre numéro de téléphone mobile.",
  sentOtpHeader: "Vérifier le numéro de téléphone",
  bankDetailsTitle: "Paiement pris en compte !",
  bankDetailsSubTitle:
    "Effectuer un dépôt sur notre compte à la Banque d'Afrique",
  bankDetailsSubDescription:
    "Maintenant, pour finaliser le dépôt sur votre compte Danapay, veuillez vous rendre dans une agence de la Bank Of Africa et effectuer un dépôt en espèces. Ou, si vous avez une banque en ligne, connectez-vous à votre compte bancaire en ligne, localisez le menu de transfert, et entrez les informations ci-dessous.",
  bd_reference: "Référence",
  complete_payment: "Paiement",
  validate_refund: "Valider le remboursement",
  Revoke_Refund: "Révoquer le remboursement",
  Validate_Release: "Valider le lancement",
  processing: "Traitement...",
  with_0_balance: "Il n'est pas possible de retirer avec un solde à 0.",
  cashout_confirm:
    "Cliquez pour sélectionner le compte sur lequel vous voulez envoyer l'argent.",
  delete: "Supprimer",
  title: "Titre",
  Instant: "Paiement instantané",
  Escrow: "Paiement avec séquestre",
  PaymentMode: "Mode de paiement",
  You_can_send_between: "Vous pouvez envoyer entre",
  Balance: "Solde",
  ExchangeRate: "Taux de change",
  TransactionType: "Type d'opération",
  Deposit: "Dépôt",
  cant_use_balance:
    "Vous ne pouvez pas utiliser votre solde pour cette opération.",
  cant_deposit_zero: "Vous ne pouvez pas faire dépôt de 0",
  MakeDeposit: "Effectuer un dépôt",
  insufficient: "Votre solde est insuffisant.",
  enterEuros: "Veuillez saisir le montant en euros",
  selectCashIn: "Veuillez choisir un mode de paiement",
  enterCfa: "Veuillez entrer le montant en CFA",
  cantSendToSelf: "Vous ne pouvez pas vous envoyer des fonds",
  Your_current_balance: "Votre solde actuel",
  selectCashinMethod: "Choisir la méthode de paiement",
  BackHome: "Accueil",
  Reference: "Référence",
  passwordLinkSent:
    "Un lien de réinitialisation du mot de passe a été envoyé à votre adresse électronique.",
  payout: "Retrait",
  Please_select_cashin_Method: "Veuillez sélectionner le mode d'encaissement",
  menuHome: "Accueil",
  menuDeveloper: "Développeur",
  menuHelp: "Aide",
  menuPolicy: "Politique de confidentialité",
  menuTerms: "Conditions générales",
  menuHistory: "Historique",
  Payment_Mode: "Mode de paiement",
  Sent_amount_EUR: "Montant en EUR",
  Sent_amount_Cfa: "Montant en CFA",
  type_withdraw_amount: "Tapez le montant du retrait",
  Enter_withdraw_Details: "Entrez les détails du retrait",
  Type_verification_code: "Tapez le code de vérification",
  TVCText: "Nous vous avons envoyé un code de vérification par SMS sur ",
  warning: "Avertissement",
  started: "Démarré",
  funds_escrowed: "Fonds séquestrés",
  transfered: "Transféré",
  released: "Libéré",
  escrowing: "Dépôt en séquestre",
  transfering: "En cours...",
  releasing: "Libérer",
  paying_in: "En cours",
  paying_out: "En cours",
  payment_failed: "Échec",
  payment_started: "Démarré",
  revoking_refund_confirmation: "Révoquer",
  payment_pending: "En attente",
  payed_out: "Versé",
  completed: "Finalisé",
  payed_in: "Déposé",
  previous: "Précédent",
  reason: "Motif du paiement",
  password_required: "Le mot de passe est requis",
  email_required: "Identifiant de l'adresse e-mail requis.",
  email_not_valid: "L'email n'est pas valide.",
  LNR: "Le nom de famille est obligatoire",
  FNR: "Le prénom est obligatoire",
  P_REG:
    "Le mot de passe doit comporter au moins 8 caractères, 1 majuscule, 1 chiffre et 1 caractère spécial.",
  PSM: "Les mots de passe doivent correspondre",
  RNE: "Le numéro d'enregistrement est requis.",
  CNE: "Le nom de la société est obligatoire.",
  AIE: "Les informations relatives à l'adresse sont requises.",
  CE: "Le pays est requis.",
  CIE: "La ville est requise.",
  "": "",
  payment_instruction: "Instruction de paiement",
  pi_text:
    "Sélectionnez la banque à partir de laquelle vous souhaitez effectuer le virement.",
  select_files: "sélectionnez les fichiers à télécharger.",
  upload: "Télécharger   ",
  uploaded_successfully: " a été téléchargé avec succès.",
  Drop_file_to_upload: "Déposer le fichier à télécharger.",
  or: "ou",
  Register_of_commerce: "Registre du commerce",
  Articles_of_association: "Statuts de l'association",
  Beneficiary_owner: "Bénéficiaire propriétaire",
  Directors_IDs: "ID des directeurs",
  Proof_of_address: "Preuve d'adresse",
  bulkPayment: "Paiements en masse",
  Upload_csv: "Télécharger un détail csv des bénéficiaires",
  Individual: "Individuel",
  Business: "Entreprise",
  Phone_Number: "Numéro de téléphone",
  Add_Contact: "Ajouter un contact",
  Latest_Transactions: "Dernières transactions ",
  Contacts: "Contacts",
  People_selected: "Personnes sélectionnées",
  Select_Beneficiaries: "Sélectionner les bénéficiaires",
  select_cashOut: "Veuillez choisir le mode de paiement",
  select_account:
    "Veuillez sélectionner un compte sur lequel envoyer l'argent.",
  balance_zero: "Il n'est pas possible de retirer avec un solde nul.",
  Withdrawal_amount: "Le montant du retrait est trop élevé.",
  select_user:
    "Sélectionnez l'utilisateur auquel vous voulez envoyer de l'argent",
  select_country: "Sélectionnez un pays",
  country_not_configured: "Le pays destinataire n'est pas encore configuré..",
  country_not_selected: "Sélectionnez un pays",
  sumSubTitle: "Procédez à votre vérification ",
  sumSubDescription:
    "Nous avons besoin de ces documents pour des raisons de conformité. Ils sont obligatoires et ne seront pas demandés à nouveau.",
  skip_later: "Passer",
  add_user: "Ajouter",
  select_users:
    "Sélectionnez l'utilisateur auquel vous voulez envoyer de l'argent",
  TRQ: "Le type d'utilisateur est requis",
  ERQ: "L'adresse électronique est obligatoire.",
  amount_out_of_range: "Montant hors de la fourchette",
  euroAmountRequired: "Le champ du montant en euros est obligatoire.",
  Make_deposit:
    "Votre opération sera finalisée une fois que vous recevrons les fonds. Veuillez à présent effectuer un virement sur notre compte ",
  the_key_code_must_be_between_5_and_6_digits:
    "Le code secret doit comporter entre 5 et 6 chiffres.",
  verification_failed: "La vérification a échoué",
  the_key_code_field_is_required: "Le champ code secret est obligatoire",
  Individual_Account: "Compte individuel",
  file_to_large:
    "Désolé, votre fichier est trop volumineux. Nous acceptons les fichiers d'une taille maximale de 5 mégaoctets.",
  pay_by_link: "Demande de paiement",
  Bulk_Payments: "Paiement de masse",
  Pay_By_Link: "Demande de de paiement",
  not_config: "n'a pas de pays de réception associé.",
  accept_payment: "accepter le paiement",
  userNotActive:
    "Votre compte n'a pas encore été activé, veuillez contacter l'administrateur de Danapay.",
  userNotActiveTitle: "Activation du compte",
  termsTextDes: " En cliquant sur s'inscrire, vous acceptez nos conditions ",
  resource_item_for_transfercountry_not_found:
    "Elément de ressource pour le pays de transfert non trouvé",
  verification: "Vérification",
  bank_transfer: "Virement bancaire",
  balance: "Solde",
  Personal_Information: "Informations personnelles",
  Company_Information: "Informations sur l'entreprise",
  Documents: "Documents",
  Verify_User: "Vérifier l'utilisateur",
  Account_Type: "Type de compte",
  select_bank: "Sélection banque",
  validated: "Validé",
  bank_details: "Confirmation",
  Back_Home: "Accueil",
  trying_to_get_property_type_of_nonobject:
    "Tentative de récupération du type de propriété d’un objet non objet",
};
