import { Form, Formik } from "formik";
import { editUser, setSignUpData } from "../../store/features/Auth/AuthSlice";
import CountryMultiSelect from "../CountryMultiSelect";
import FormErrorText from "../FormErrorText";
import {
  addUser,
  detachCompany,
  saveRegistrationProgress,
} from "../../store/features/Auth/Auth";
import * as Sentry from "@sentry/browser";
import { isEmpty, isFromAfrica } from "../../utilities/help";
import { toast } from "material-react-toastify";
import { KeyboardArrowDown } from "@material-ui/icons";
import CustomButton from "../CustomButton";

const IndividualTransferInfo = (props: any) => {
  const {
    user,
    t,
    transferReceivingCountries,
    countryListing,
    dispatch,
    signUpData,
    setprogressBarData,
    setActiveStep,
    Yup,
    settransferReceivingCountries,
    fetchingCountryListing,
    setresidencyLevelName,
    typeOfUser,
  } = props;

  const handleUserCreation = (data: any, setSubmitting: any, formData: any) => {
    try {
      setSubmitting(true);
      const usertype: any = typeOfUser.find((itm: any) => itm.active);
      let payload = { ...formData };
      delete payload.customJob;
      delete payload.company;
      delete payload.countryName;

      const onSuccess = () => {
        setActiveStep(4);
        setprogressBarData({
          progress: 100,
          title: "documents_and_selfie",
        });
      };

      const onFail = (error: any) => {
        setSubmitting(false);
        toast.warning(t(error?.data?.message));
        Sentry.captureException(error);
      };

      // if company is attached, remove it
      if (
        (user?.company !== null && user?.company !== undefined) ||
        (signUpData?.company !== null && signUpData?.company !== undefined)
      ) {
        detachCompany()
          .then(() => {
            addUser(payload, usertype.value)
              .then((res: any) => {
                if (res?.missing_fields?.length < 1 && res?.completed == true) {
                  dispatch(editUser(res?.user));
                  onSuccess();
                } else {
                  if (res?.missing_fields?.length > 0) {
                    res?.missing_fields.forEach((missingField: any) => {
                      toast.error(`${missingField} ${t("is_required")}`);
                    });
                  }
                }
              })
              .catch((error: any) => {
                onFail(error);
                if (error?.data?.errors) {
                  try {
                    Object.keys(error?.data?.errors).forEach((error: any) => {
                      toast.error(`${t(error)}${t("is_invalid")}`);
                    });
                  } catch (error) {
                    Sentry.captureException(error);
                  }
                }
              })
              .finally(() => setSubmitting(false));
          })
          .catch((error: any) => {
            onFail(error);
          });
      } else {
        addUser(payload, usertype.value)
          .then((res: any) => {
            if (res?.missing_fields?.length < 1 && res?.completed == true) {
              dispatch(editUser(res?.user));
              onSuccess();
            } else {
              if (res?.missing_fields?.length > 0) {
                res?.missing_fields.forEach((missingField: any) => {
                  toast.error(`${missingField} ${t("is_required")}`);
                });
              }
            }
          })
          .catch((error: any) => {
            onFail(error);
            if (error?.data?.errors) {
              try {
                Object.keys(error?.data?.errors).forEach((error: any) => {
                  toast.error(`${t(error)}${t("is_invalid")}`);
                });
              } catch (error) {
                Sentry.captureException(error);
              }
            }
          })
          .finally(() => setSubmitting(false));
      }
    } catch (error: any) {
      setSubmitting(false);
      Sentry.captureException(error);
    }
  };

  const handleSubmit = (data: any, setSubmitting: any) => {
    try {
      setSubmitting(true);
      const isUserAfricanResident = isFromAfrica(signUpData?.country_code);
      const receivingIds = countryListing
        .filter((country: any) => data.countrySelection.includes(country.name))
        .map((country: any) => country.id);

      // send user to sumsub if from africa else send to residency selection
      if (isUserAfricanResident) {
        setresidencyLevelName("WAEMU/CEMAC Person");
        dispatch(
          setSignUpData({
            ...signUpData,
            receiving_countries_ids: receivingIds,
            residency: "WAEMU/CEMAC Person",
          })
        );
        saveRegistrationProgress({
          ...signUpData,
          residency: "WAEMU/CEMAC Person",
          receiving_countries_ids: receivingIds,
        }).finally(() => {
          handleUserCreation(data, setSubmitting, {
            ...signUpData,
            receiving_countries_ids: receivingIds,
            residency: "WAEMU/CEMAC Person",
          });
        });
      } else {
        dispatch(
          setSignUpData({
            ...signUpData,
            receiving_countries_ids: receivingIds,
          })
        );
        saveRegistrationProgress({
          ...signUpData,
          receiving_countries_ids: receivingIds,
        }).finally(() => {
          let verificationPayload = {
            ...user.progression,
            ...signUpData,
          };

          let finalPayload: any = {
            user_origin: verificationPayload.user_origin,
            event: verificationPayload?.event,
            referral_code: verificationPayload?.referral_code,
            is_sole_proprietorship: verificationPayload.is_sole_proprietorship,
            first_name: verificationPayload.first_name,
            last_name: verificationPayload.last_name,
            email: verificationPayload.email,
            phone_number: verificationPayload.phone_number,
            country_code: verificationPayload.country_code,
            city: verificationPayload.city,
            post_code: verificationPayload.post_code,
            countryName: verificationPayload.countryName,
            address_line: verificationPayload.address_line,
            monthly_revenue: verificationPayload.monthly_revenue,
            receiving_countries_ids: receivingIds,
            usertype: "individual",
            step: 3,
          };

          if (!isEmpty(verificationPayload?.job)) {
            finalPayload.job = verificationPayload?.job;
          } else {
            finalPayload.activity_id = verificationPayload?.activity_id;
          }

          addUser(
            {
              ...finalPayload,
            },
            "individual"
          )
            .then(() => {
              setprogressBarData({
                progress: 80,
                title: "residency_details",
              });
              setActiveStep("residenceSelection");
            })
            .catch((err) => {
              Sentry.captureException(err);
              if (err?.data?.errors) {
                try {
                  Object.keys(err?.data?.errors).forEach((error: any) => {
                    toast.error(`${t(error)}${t("is_invalid")}`);
                  });
                } catch (error) {
                  Sentry.captureException(error);
                }
              }
            })
            .finally(() => {
              setSubmitting(false);
            });
        });
      }
    } catch (error) {
      setSubmitting(false);
      Sentry.captureException(error);
    }
  };

  const validationSchema = Yup.object().shape({
    countrySelection: Yup.array()
      .min(1, "transfer_countries_required")
      .required("required"),
  });

  return (
    <div className="flex flex-col max-w-[600px] justify-center px-4 py-7 mb-5 max-sm:px-4 max-sm:py-8 w-full bg-white rounded-xl shadow-c">
      <div className="font-bold text-center text-2xl">
        {t("country_destination")}
      </div>
      <div className="opacity-75 text-center mb-7">
        {t("countries_to_send")}
      </div>
      <Formik
        validateOnMount
        enableReinitialize
        key="individualTransferInfo"
        initialValues={{
          countrySelection: transferReceivingCountries,
        }}
        onSubmit={(data: any, { setSubmitting }) => {
          handleSubmit(data, setSubmitting);
        }}
        validationSchema={validationSchema}
      >
        {({ errors, setFieldValue, isSubmitting, isValid }) => (
          <Form className="individualTransferinfo">
            <div className="mb-4">
              <CountryMultiSelect
                onCancel={() => {
                  settransferReceivingCountries([]);
                  setFieldValue("countrySelection", []);
                }}
                CustomIcon={
                  <KeyboardArrowDown
                    style={{ width: "18px", height: "21px", color: "black" }}
                  />
                }
                isLoading={fetchingCountryListing}
                selectedValues={transferReceivingCountries}
                placeholder={t("sending_countries_placeholder")}
                list={countryListing}
                onChange={(e: any) => {
                  settransferReceivingCountries(e);
                  setFieldValue("countrySelection", e);
                }}
                customClasses="!rounded-xl h-[50px] w-full border-1 pl-5 pr-2 border-[rgba(0,0,0,0.2)]"
              />
              {errors?.countrySelection && (
                <FormErrorText
                  fontSize={12}
                  classNames="text-xs"
                  errorMessage={errors?.countrySelection}
                />
              )}
            </div>

            <div className="flex justify-between gap-3">
              <CustomButton
                onClick={() => {
                  setprogressBarData({
                    progress: 40,
                    title: "revenue_information",
                  });
                  setActiveStep(2);
                }}
                label="back"
              />{" "}
              <CustomButton
                classNames="min-h-[48px] flex-1 rounded-xl text-white capitalize text-sm font-bold bg-black"
                label="next"
                type="submit"
                data-individual="individual"
                disabled={isSubmitting || !isValid || fetchingCountryListing}
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default IndividualTransferInfo;
