import { Tooltip } from "@material-ui/core";
import { InfoOutlined } from "@material-ui/icons";
import { getMobileMoneySvg } from "../../utilities/help";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

const MobileMoney = (props: any) => {
  const { transaction, recipientMobileNumber } = props;
  const { operator = undefined } = transaction?.data;
  return (
    <>
      <img
        src={`../logos/${getMobileMoneySvg(operator)}.svg`}
        style={{
          height: 20,
          width: 20,
        }}
        className="mr-1"
        alt=""
      />
      {recipientMobileNumber}
    </>
  );
};

const Deposit = () => {
  const { t } = useTranslation();
  return (
    <>
      <img
        src={`../logos/danapaylogo.svg`}
        style={{
          height: 20,
          width: 20,
        }}
        className="mr-1"
        alt=""
      />
      {t("danapay_account")}
      <Tooltip
        title={`${t("danapay_account_tooltip")}`}
        placement="right-start"
        className="ml-1"
      >
        <InfoOutlined
          className="text-xs"
          style={{ fontSize: 12, color: "#2DC4B6" }}
        />
      </Tooltip>
    </>
  );
};

const BankAccount = (props: any) => {
  const { transaction } = props;
  const { t } = useTranslation();
  const { iban = undefined } = transaction?.data;

  return (
    <>
      {t("account_ending_with")}
      {iban.slice(-4)}
    </>
  );
};

const TransactionRecipient = (props: any) => {
  const {
    logged_in_transactor,
    operation_type,
    transaction = undefined,
    returnUser,
    t,
    showAccountType = false,
    isWeblist = false,
    fullName = null,
    isSender = false,
  } = props;

  const recipientMobileNumber = useMemo(() => {
    if (
      transaction?.data &&
      transaction?.data.country_code &&
      transaction?.data.phone_number
    ) {
      return (
        "+" +
        transaction?.data?.country_code +
        " " +
        transaction?.data?.phone_number
      );
    } else {
      return "";
    }
  }, [transaction]);

  const transactionType = () => {
    if (transaction?.data) {
      const dataKeys = Object.keys(transaction?.data);
      if (transaction?.data && dataKeys.includes("operator")) {
        return (
          <MobileMoney
            transaction={transaction}
            recipientMobileNumber={recipientMobileNumber}
          />
        );
      } else if (transaction?.data && dataKeys.includes("mfi_name")) {
        return "mfi";
      } else if (transaction?.data && dataKeys.includes("iban")) {
        return <BankAccount transaction={transaction} />;
      } else {
        return <Deposit />;
      }
    } else {
      return <>{returnUser()?.full_phone_number}</>;
    }
  };

  const getRecipientAccountType = () => {
    if (
      logged_in_transactor === "receiver" &&
      (operation_type === "direct_transfer" ||
        operation_type === "instant_transfer")
    ) {
      if (
        transaction?.source_user?.is_individual == true ||
        transaction?.source_user?.is_individual == 1
      ) {
        return "Individual_Account";
      } else {
        return "professional_account";
      }
    } else {
      if (
        returnUser()?.is_individual == true ||
        returnUser()?.is_individual == 1
      ) {
        return "Individual_Account";
      } else {
        return "professional_account";
      }
    }
  };

  return (
    <div className="relative min-w-[150px] max-w-[200px]">
      {fullName ? (
        <div className="capitalize font-bold opacity-75">{fullName}</div>
      ) : (
        <div className="capitalize text-lg opacity-75">
          {logged_in_transactor === "receiver" &&
          (operation_type === "direct_transfer" ||
            operation_type === "instant_transfer")
            ? transaction?.source_user?.full_name?.trim().toLowerCase()
            : returnUser()?.full_name?.trim().toLowerCase() ||
              returnUser()?.full_phone_number}
        </div>
      )}

      <div className="text-sm opacity-50">
        {isSender ? t("sent") : t("reception")}
      </div>
    </div>
  );
};

export default TransactionRecipient;
