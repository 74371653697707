import CashLayout from "../CashLayout";
import { useAppSelector } from "../../store/hooks";
import ConvertedAmount from "../ConvertedAmount";

const TransactionInfo = (props: any) => {
  const { fetchData, isSender, t, directFeeSObject } = props;
  const {
    auth: { lang },
  } = useAppSelector((state) => state.persistedReducer);

  const dataKeys: any = {};
  const dataObj: any = {};

  return (
    <div className="px-4 pb-4 flex flex-col justify-between">
      <div>
        <div className="font-bold mb-4">
          <>{t("TransactionDetails")}</>
        </div>
        <div className="bg-white flex flex-col gap-3">
          {/* amount */}
          {fetchData?.sent_amount_in_sender_currency && (
            <div className="flex justify-between items-center text-balance">
              <span className="opacity-75 text-black text-sm font-bold">
                {t("Sent_amount")}
              </span>

              <ConvertedAmount
                cashCurrency={fetchData?.sender_currency}
                cash={fetchData?.sent_amount_in_sender_currency}
                containerClasses="text-sm text-black font-bold"
              />
            </div>
          )}

          {/* fees */}
          {fetchData?.fee_in_sender_currency && (
            <>
              <div className="flex justify-between text-sm">
                <span className="opacity-75">{t("fee_paid")}</span>
                <b>
                  <ConvertedAmount
                    cashCurrency={fetchData?.sender_currency}
                    cash={
                      fetchData?.fee_in_sender_currency +
                      fetchData?.sender_currency
                    }
                    containerClasses="opacity-75 font-bold text-sm"
                  />
                </b>
              </div>

              {fetchData?.operation_type === "direct_transfer" &&
                fetchData?.transfer?.type !== "BulkTransfer" && (
                  <div className="flex justify-between mb-3 text-sm">
                    <span>{t("withdrawal_Fees")}</span>
                    <b>
                      <CashLayout
                        classes=" opacity-75"
                        // showCurrencyIcon={true}
                        cash={
                          directFeeSObject?.cashout_fee === undefined ||
                          directFeeSObject?.cashout_fee === null
                            ? 0
                            : directFeeSObject?.cashout_fee
                        }
                      />
                    </b>
                  </div>
                )}
            </>
          )}
          {fetchData?.payment_type && (
            <>
              <div className="flex justify-between text-sm">
                <span className="opacity-75">{t("Payment_Mode")}</span>
                <div className="capitalize text-right">
                  {t(fetchData?.payment_type)}
                </div>
              </div>
            </>
          )}

          {/* final amount */}
          <div className="flex justify-between">
            <div className="opacity-75 text-sm font-bold">
              {isSender ? t("Received_amount") : t("Received_amount")}
            </div>
            <b className="text-sm text-black">
              {fetchData?.operation_type === "bulk_transfer" ? (
                <CashLayout
                  fontSize={24}
                  cash={
                    fetchData?.transfer?.bulk_transfer_recipient_details.reduce(
                      (sum: number, user: any) =>
                        sum + parseFloat(user.amount_in_euros),
                      0
                    ) * fetchData.exchange_rate_applied
                  }
                  isCfa={true}
                  exchange_rate={1}
                />
              ) : (
                <ConvertedAmount
                  cashCurrency={fetchData?.receiver_currency}
                  cash={fetchData?.received_amount_in_receiver_currency}
                  containerClasses="font-bold text-sm"
                />
              )}
            </b>
          </div>

          {fetchData && fetchData.hasOwnProperty("operator_name") ? (
            <div className="flex flex-col gap-3  text-sm">
              <div className="flex items-center justify-between opacity-75">
                <div>{t("receiving_mode")}</div>
                <div>
                  {lang == "en"
                    ? fetchData?.withdraw_method_en
                    : fetchData?.withdraw_method_fr}
                </div>
              </div>
              <div className="flex items-center justify-between opacity-75">
                <div>{t("operator")}</div>
                <div>{fetchData?.operator_name}</div>
              </div>
              <div className="flex items-center justify-between font-semibold !text-[#037375]">
                <div className="font-bold">{t("number")}</div>
                <div>{`+${fetchData?.country_code} ${fetchData?.phone_number}`}</div>
              </div>
            </div>
          ) : fetchData && fetchData.hasOwnProperty("iban") ? (
            <div className="flex flex-col gap-3  text-sm">
              <div className="flex items-center justify-between opacity-75">
                <div>{t("receiving_mode")}</div>
                <div>
                  {lang == "en"
                    ? fetchData?.withdraw_method_en
                    : fetchData?.withdraw_method_fr}
                </div>
              </div>
              <div className="flex items-center justify-between opacity-75">
                <div>{t("bank")}</div>
                <div>{fetchData?.bank_name}</div>
              </div>
              <div className="flex items-center justify-between font-semibold !text-[#037375]">
                <div className="font-bold">{t("IBAN")}</div>
                <div>{fetchData?.iban}</div>
              </div>
            </div>
          ) : fetchData && fetchData["withdraw_method_en"] === "Delivery" ? (
            <div className="flex flex-col gap-3  text-sm">
              <div className="flex items-center justify-between font-semibold !text-[#037375]">
                <div className="font-bold">{t("receiving_mode")}</div>
                <div>{t("delivery")}</div>
              </div>
            </div>
          ) : (
            fetchData &&
            fetchData.hasOwnProperty("mfi_owner_id") && (
              <div className="flex flex-col gap-3  text-sm">
                <div className="flex items-center justify-between opacity-75">
                  <div>{t("receiving_mode")}</div>
                  <div>
                    {lang == "en"
                      ? fetchData?.withdraw_method_en
                      : fetchData?.withdraw_method_fr}
                  </div>
                </div>
                <div className="flex items-center justify-between opacity-75">
                  <div>{t("owner_name")}</div>
                  <div>{fetchData?.mfi_name}</div>
                </div>
                <div className="flex items-center justify-between font-semibold !text-[#037375]">
                  <div className="font-bold">{t("Account id")}</div>
                  <div>{fetchData?.mfi_owner_id}</div>
                </div>
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default TransactionInfo;
