import React, { useState, useEffect, useMemo, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import Modal from "@material-ui/core/Modal";
import { toast } from "material-react-toastify";
import { Formik, Form } from "formik";
import * as yup from "yup";
import * as Sentry from "@sentry/browser";
import {
  addContact,
  checkUserNameIfReal,
  getFavorites,
} from "../store/features/Auth/Auth";
import { extractError } from "../utility";
import "react-phone-input-2/lib/style.css";
import { useTranslation } from "react-i18next";
import "../helpers/i18n";
import { saveUserFavorites } from "../store/features/Auth/AuthSlice";
import { getCountryByCode, getPreferredComChannel } from "../utilities/help";
import {
  addBeneficiary,
  addTransferCountry,
  getAllMobileOperatorWithCode,
  setBeneFound,
  setStep,
  setTransferDialogOpen,
} from "../store/features/Transfer/TransferSlice";
import CountryPicker from "./CountryPicker";
import InputField from "./forms/InputField";
import Contact from "./Contact";
import { getAllMobileOperatorWithCountryCode } from "../store/features/Transfer/Transfer";
import { Tab, Tabs } from "@material-ui/core";
import FormErrorText from "./FormErrorText";
import CustomModal from "./CustomModal";

const Contacts: React.FC<any> = ({ buttonOnly }) => {
  const {
    transfer,
    auth: { user, favorites },
  } = useAppSelector((state) => state.persistedReducer);
  const dispatch = useAppDispatch();
  const [open, setIsOPen] = useState(false);
  const [preComChannel, setPreComChannel] = useState(null);
  const { t } = useTranslation();
  const [receivingCountries, setReceivingCountries] = React.useState<any>([]);
  const [selectedCountryReset, setSelectedCountryReset] =
    useState<boolean>(false);
  const [allMobileOperator, setAllMobileOperator] = useState<any>([]);

  const refreshContacts = () => {
    getFavorites()
      .then((response) => {
        dispatch(saveUserFavorites(response));
      })
      .catch((error: any) => {
        Sentry.captureException(error);
        toast.error(t(error?.data?.message));
      });
  };

  const moveToTransfer = (user: any) => {
    // set hub accounts according to country of user(recipient)

    try {
      const beneficiary_country = getCountryByCode(
        transfer.danaPayCountries,
        user?.country_code
      );
      dispatch(addBeneficiary(user));
      dispatch(setBeneFound(true));
      dispatch(addTransferCountry(beneficiary_country));
      dispatch(setStep(0));
      dispatch(setTransferDialogOpen(!transfer.isTransferDialogOpen));
      const filterOperator: any = transfer.danaPayCountries.filter(
        (e: any) => e.country_code?.toString() === user?.country_code.toString()
      );
      getAllMobileOperatorWithCountryCode(filterOperator[0]?.code)
        .then((res: any) => {
          setAllMobileOperator(res);
          dispatch(getAllMobileOperatorWithCode(res));
        })
        .catch((error: any) => {
          if (error) {
            toast.error(t(extractError(error)));
          }
          Sentry.captureException(error);
        });
    } catch (error: any) {
      Sentry.captureException(error);
    }
  };

  useEffect(() => {
    try {
      const countries = transfer?.danaPayCountries?.find(
        (res: any) => res.name?.toLowerCase() === user?.country?.toLowerCase()
      );
      const ARC =
        countries?.receiving_countries.map((cc: any) =>
          cc.receiving_country?.code?.toLowerCase()
        ) || [];
      setReceivingCountries(ARC);
    } catch (error: any) {
      Sentry.captureException(error);
    }
  }, [favorites]);

  // new contact form handling
  const formRef = useRef<any>();
  const [contactType, setContactType] = React.useState(0);

  const handleTypeOfUserSelection = (
    event: React.ChangeEvent<{}>,
    newValue: number
  ) => {
    setContactType(newValue);
    if (formRef?.current?.resetForm !== undefined) {
      formRef.current.resetForm();
    }
  };

  const validationSchemaAccordingToUserType = useMemo(
    () =>
      contactType == 0
        ? yup.object().shape({
            company_name: yup.string().required("company_name_error"),
            contact_first_name: yup
              .string()
              .required("contact_first_name_error"),
            contact_last_name: yup.string().required("contact_last_name_error"),
            contact_email:
              preComChannel === "mail"
                ? yup.string().required("ERQ").email("email_not_valid")
                : yup.string().email("email_not_valid").notRequired(),
            phone_number: yup.string().required("PhoneNumberError"),
            country_code: yup.string().required("CE"),
          })
        : yup.object().shape({
            last_name: yup.string().required("LNR"),
            first_name: yup.string().required("FNR"),
            email:
              preComChannel === "mail"
                ? yup.string().required("ERQ").email("email_not_valid")
                : yup.string().email("email_not_valid").notRequired(),
            phone_number: yup.string().required("PhoneNumberError"),
            country_code: yup.string().required("CE"),
          }),
    [contactType]
  );

  const [inHumanNamePopupOpen, setinHumanNamePopupOpen] = useState(false);
  const [creatingUser, setCreatingUser] = useState(false);

  const handleUserCreateSubmission = async (data: any, setSubmitting: any) => {
    try {
      setSubmitting(true);

      // check for valid username
      setCreatingUser(true);
      const full_name = data?.first_name + " " + data?.last_name;
      checkUserNameIfReal({ full_name })
        .then((response: any) => {
          if (response.isRealName) {
            setinHumanNamePopupOpen(false);
            createUser(data, setSubmitting);
          } else {
            setinHumanNamePopupOpen(true);
          }
        })
        .catch((error) => {
          Sentry.captureException(error);
          setinHumanNamePopupOpen(false);
          setSubmitting(false);
          setCreatingUser(false);
          toast.error(t(error?.data?.message));
        });
    } catch (error: any) {
      toast.error(t(error));
      setCreatingUser(false);
      setSubmitting(false);
    }
  };

  const createUser = async (data: any, setSubmitting: any) => {
    try {
      if (contactType == 1) {
        const newContact = {
          ...data,
          country_code: parseInt(data.country_code),
          is_individual: true,
        };

        if (!data.email) {
          delete newContact.email;
        }

        addContact(newContact)
          .then((res: any) => {
            toast.success(t("contact_added"));
            refreshContacts();
            setIsOPen(false);
            setSubmitting(false);
          })
          .catch((error: any) => {
            setSubmitting(false);
            Sentry.captureException(error);

            toast.error(t(extractError(error)));
          })
          .finally(() => setCreatingUser(false));
      } else {
        const newContact = {
          company_name: data.company_name,
          phone_number: data.phone_number,
          country_code: parseInt(data.country_code),
          first_name: data?.contact_first_name,
          last_name: data?.contact_last_name,
          email: data?.contact_email,
          is_individual: false,
        };

        if (!data.email) {
          delete newContact.email;
        }
        setSubmitting(true);
        addContact(newContact)
          .then((res: any) => {
            toast.success(t("contact_added"));
            refreshContacts();
            setIsOPen(false);
            setSubmitting(false);
          })
          .catch((error: any) => {
            setSubmitting(false);
            toast.error(t(extractError(error)));
            Sentry.captureException(error);
          })
          .finally(() => setCreatingUser(false));
      }
    } catch (error) {
      toast.error(t(extractError(error)));
      setSubmitting(false);
      setCreatingUser(false);
      Sentry.captureException(error);
    }
  };

  return (
    <>
      <div className="flex flex-row py-3 overflow-x-scroll rounded-lg">
        <div
          className="flex flex-col items-center justify-center mr-4 cursor-pointer"
          onClick={() => setIsOPen(!open)}
        >
          <div
            style={{ height: 55, width: 55, borderRadius: 50 }}
            className="flex items-center justify-center bg-gray-200"
          >
            <i className="m-0 font-bold text-gray-700 fa fa-plus" />
          </div>
          <small
            className="text-center text-gray-700 capitalize"
            style={{ fontSize: 11, marginTop: 5 }}
          >
            {t("add_user")}
          </small>
        </div>
        {!buttonOnly && (
          <>
            {favorites
              ?.filter((user: any) => user?.favorite.full_name !== " ")
              .map((contact: any, index: number) => (
                <Contact
                  key={index + 12}
                  keyprop={index}
                  contact={contact}
                  moveToTransfer={() => moveToTransfer(contact.favorite)}
                />
              ))}
          </>
        )}
      </div>

      {/* add contact modal */}
      <Modal open={open} onClose={() => setIsOPen(!open)}>
        <div className="m-auto h-full overflow-y-scroll justify-center flex items-center row contactscomponent">
          <div className="col-md-6">
            <div className="p-3 bg-white flex flex-col rounded-md">
              <div className="flex flex-row items-center justify-between mb-2">
                <div className="font-bold">{t("Add_New_Contact")}</div>
                <button
                  onClick={() => {
                    setIsOPen(!open);
                    setinHumanNamePopupOpen(false);
                    setCreatingUser(false);
                  }}
                  style={{ height: 40, width: 40, borderRadius: 20 }}
                  className="flex items-center justify-center shadow-lg"
                >
                  <i className="m-0 fa fa-close"></i>
                </button>
              </div>
              <hr className="mb-2" />

              <div className="mb-2">
                <Tabs
                  value={contactType}
                  onChange={handleTypeOfUserSelection}
                  variant="fullWidth"
                >
                  <Tab label={t("Business")} />
                  <Tab label={t("Individual")} />
                </Tabs>
              </div>
              <small className="mb-4 text-gray-400">
                {t("Add_New_Contact_Info")}
              </small>

              <Formik
                validateOnMount
                enableReinitialize
                innerRef={formRef}
                initialValues={
                  contactType == 1
                    ? {
                        first_name: "",
                        last_name: "",
                        email: "",
                        phone_number: "",
                        country_code: "",
                      }
                    : {
                        company_name: "",
                        contact_first_name: "",
                        contact_last_name: "",
                        contact_email: "",
                        phone_number: "",
                        country_code: "",
                      }
                }
                onSubmit={async (data: any, { setSubmitting }) => {
                  handleUserCreateSubmission(data, setSubmitting);
                }}
                validationSchema={validationSchemaAccordingToUserType}
              >
                {({
                  values,
                  handleChange,
                  handleBlur,
                  errors,
                  touched,
                  isValid,
                  setFieldTouched,
                  dirty,
                  handleSubmit,
                  isSubmitting,
                  setSubmitting,
                }) => (
                  <Form>
                    {contactType == 0 && (
                      <InputField
                        extraClasses="mb-3 customInputStyle"
                        name="company_name"
                        handleChange={handleChange}
                        onBlur={handleBlur}
                        placeholder={t("CompanyName")}
                        value={values.last_name}
                        label={t("CompanyName")}
                        error={t(`${errors.company_name}`)}
                        touched={touched.company_name}
                      />
                    )}

                    <InputField
                      extraClasses="mb-3 customInputStyle"
                      name={
                        contactType == 0 ? "contact_first_name" : "first_name"
                      }
                      handleChange={handleChange}
                      onBlur={handleBlur}
                      placeholder={
                        contactType == 0
                          ? t("contact_first_name")
                          : t("FirstName")
                      }
                      value={values.first_name}
                      label={
                        contactType == 0
                          ? t("contact_first_name")
                          : t("FirstName")
                      }
                      error={
                        contactType == 0 && touched.contact_first_name
                          ? t(`${errors.contact_first_name}`)
                          : touched.first_name
                          ? t(`${errors.first_name}`)
                          : ""
                      }
                      touched={
                        contactType == 0
                          ? touched?.contact_first_name!
                          : touched.first_name
                      }
                    />
                    <InputField
                      extraClasses="mb-3 customInputStyle"
                      name={
                        contactType == 0 ? "contact_last_name" : "last_name"
                      }
                      handleChange={handleChange}
                      onBlur={handleBlur}
                      placeholder={
                        contactType == 0
                          ? t("contact_last_name")
                          : t("LastName")
                      }
                      value={values.last_name}
                      label={
                        contactType == 0
                          ? t("contact_last_name")
                          : t("LastName")
                      }
                      error={
                        contactType == 0 && touched.contact_last_name
                          ? t(`${errors.contact_last_name}`)
                          : touched.last_name
                          ? t(`${errors.last_name}`)
                          : ""
                      }
                      touched={
                        contactType == 0
                          ? touched.contact_last_name
                          : touched.last_name
                      }
                    />

                    <div className="mb-3">
                      <small className="text-gray-600 form-label">
                        {t("Phone_Number")}
                      </small>
                      <CountryPicker
                        setCountry={(text: any) => {
                          handleChange("country_code")(text.mobileCode);
                          setFieldTouched("country_code", true, false);
                          setPreComChannel(
                            getPreferredComChannel(
                              transfer.danaPayCountries,
                              text.mobileCode
                            )
                          );
                        }}
                        cash_Out_Method_Name={""}
                        country={null}
                        tab={contactType}
                        onChange={(text: string) => {
                          handleChange("phone_number")(text);
                          setFieldTouched("phone_number", true, false);
                        }}
                        value={values.phone_number}
                        withPhoneInput={true}
                        country_selected={true}
                        setSelectedCountry={(e: any) =>
                          setSelectedCountryReset(e)
                        }
                      />
                      {errors.country_code && touched.phone_number && (
                        <FormErrorText errorMessage={errors.country_code} />
                      )}
                      {errors.phone_number && touched.phone_number && (
                        <FormErrorText errorMessage={errors.phone_number} />
                      )}
                    </div>

                    <InputField
                      extraClasses="mb-3 customInputStyle"
                      name={contactType == 0 ? "contact_email" : "email"}
                      handleChange={handleChange}
                      onBlur={handleBlur}
                      placeholder={
                        contactType == 0
                          ? t("contact_email")
                          : t("Email_Address")
                      }
                      value={values.email}
                      label={
                        contactType == 0
                          ? t("contact_email")
                          : `${t("Email_Address")} ${
                              preComChannel !== "mail" && `[${t("optional")}]`
                            }`
                      }
                      error={
                        contactType == 0
                          ? t(`${errors.contact_email}`)
                          : t(`${errors.email}`)
                      }
                      touched={
                        contactType == 0 ? touched.contact_email : touched.email
                      }
                    />

                    <button
                      className="w-full mt-4 rounded-lg btn rounded-lg-primary text-white font-bold leading-6 text-[12.8px]"
                      style={{ backgroundColor: "rgb(3, 115, 117)" }}
                      type="submit"
                      disabled={
                        !(isValid && dirty) || isSubmitting || creatingUser
                      }
                    >
                      {isSubmitting ? t("pending_title") : t("Invite_Contact")}
                    </button>

                    {/* inhuman name popup */}
                    <CustomModal
                      open={inHumanNamePopupOpen}
                      containerClasses={
                        "w-full h-full flex justify-center text-center items-center"
                      }
                      modalClasses={
                        "bg-white m-auto p-4 rounded col-md-6 col-lg-5 col-11"
                      }
                      titleClasses={
                        "font-bold text-xl max-sm:text-lg mt-2 mb-4"
                      }
                      title={t("inhuman_name_error")}
                      contentClasses={"mt-4"}
                      onBack={() => {
                        setinHumanNamePopupOpen(false);
                        setSubmitting(false);
                        setCreatingUser(false);
                      }}
                      onNext={() => {
                        createUser(values, setSubmitting);
                        setinHumanNamePopupOpen(false);
                      }}
                      onBackTitle={"go_back_and_change"}
                      onNextTitle={"proceed"}
                      buttonContainerClasses={"flex gap-3 max-sm:flex-col"}
                      backBtnClasses="w-full rounded-lg btn btn-block rounded-lg-primary border md:text-md max-sm:!text-sm bg-white !text-black"
                    />
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Contacts;
