import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../store/hooks";
import { useMemo } from "react";
import * as Sentry from "@sentry/browser";
import { useHistory } from "react-router-dom";
import { openWithdraw } from "../../store/features/Transfer/TransferSlice";
import { useDispatch } from "react-redux";
import { Check } from "@material-ui/icons";
import checkgif from "../../../public/check.gif";

const WithdrawSuccessScreen = (props: any) => {
  const { method, cashoutResponse } = props;
  const { t } = useTranslation();
  const {
    auth: { user },
    transfer,
  } = useAppSelector((state) => state.persistedReducer);
  const dispatch = useDispatch();
  const history = useHistory();

  const type = useMemo(() => {
    try {
      if (transfer && method) {
        const methods = transfer.loggedInUserCountry.cashout_methods;
        const selectedMethod = methods.find((cashoutMethod: any) => {
          return cashoutMethod.cashout_method.id == method;
        });
        if (selectedMethod) {
          return selectedMethod.cashout_method.payment_type.name;
        } else {
          return "";
        }
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  }, [transfer, method]);

  return (
    <div className="col-lg-6 col-md-10 col-12 my-3 mx-auto shadow-lg max-sm:!shadow-md p-4 max-sm:!px-2 rounded-lg">
      <div className="maininfo flex flex-col items-center">
        {/* top */}
        <div className="flex flex-col items-center">
          <img
            className={`left-10 w-[90px] h-[90px] max-md:hidden`}
            src="/checkanimation.gif"
            alt=""
            width={90}
            height={90}
          />

          <div className="mt-7 mb-7">
            <div className="font-bold text-2xl text-center">
              {t("withdraw_confirmation_title")}
            </div>
            <div className="text-center">{t("withdraw_request_taken")}</div>
          </div>
        </div>

        <div className="text-left w-full flex flex-col mb-7">
          <div className="flex justify-between items-center opacity-50 text-sm mb-3.5">
            <div className="">{t("fee_paid")}</div>
            <div>{cashoutResponse?.cashout?.applied_fee}€</div>
          </div>

          <div className="flex justify-between items-center mb-3.5">
            <div className="opacity-50 text-sm">{t("received_amount")}</div>
            <div className="font-bold opacity-75 text-lg">
              {cashoutResponse?.cashout?.amount_in_euros}€
            </div>
          </div>

          <div
            className={
              type == "delivery"
                ? "flex justify-between items-center text-[#037375] font-bold mb-3.5"
                : "flex justify-between items-center opacity-50 text-sm mb-3.5"
            }
          >
            <div className="">{t("receiving_mode")}</div>
            {type == "mobile_money" && <>{t("mobile_money")}</>}
            {(type == "bank_transfer" || type == "manual_bank_transfer") && (
              <>{t("bank_payout")}</>
            )}
            {type == "delivery" && <>{t("delivery")}</>}
            {(type == "mfi" || type == "microfinance") && <>{t("mfi")}</>}
          </div>

          {type !== "delivery" && (
            <div className="flex flex-col justify-between items-center">
              {(type == "bank_transfer" || type == "manual_bank_transfer") && (
                <>
                  <div className="flex justify-between items-center w-full opacity-50 text-sm mb-3.5">
                    <div>{t("bank")}</div>
                    <div>{transfer.withdrawInfo?.bank_name}</div>
                  </div>
                  <div className="flex justify-between items-center w-full text-[#037375] font-semibold">
                    <div>IBAN:</div>
                    <div>{transfer.withdrawInfo?.iban}</div>
                  </div>
                </>
              )}

              {(type == "microfinance" || type == "mfi") && (
                <>
                  <div className="flex justify-between items-center w-full opacity-50 text-sm mb-3.5">
                    <div>{t("mfi_name")}</div>
                    <div>{transfer.withdrawInfo?.mfi_name}</div>
                  </div>
                  <div className="flex justify-between items-center w-full text-[#037375] font-semibold">
                    <div>{t("mfi_owner_id")}</div>
                    <div>{transfer.withdrawInfo?.owner_id}</div>
                  </div>
                </>
              )}
              {type == "mobile_money" && (
                <>
                  <div className="flex justify-between items-center w-full opacity-50 text-sm mb-3.5">
                    <div>{t("operator")}</div>
                    <div>{transfer.withdrawInfo?.operator}</div>
                  </div>
                  <div className="flex justify-between items-center w-full text-[#037375] font-semibold">
                    <div>{t("withdraw_number")}</div>
                    <div>
                      {" "}
                      {`+${user.country_code} ${transfer.withdrawInfo.phone_number}`}
                    </div>
                  </div>
                </>
              )}
            </div>
          )}
        </div>

        <div className="bg-[#F7E4CD] w-full flex p-2 rounded-lg mb-7">
          <div>
            <svg
              width={40}
              height={40}
              className="px-2"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 22 22"
              fill="none"
            >
              <path
                d="M10.0833 6.41667H11.9167V8.25H10.0833V6.41667ZM10.0833 10.0833H11.9167V15.5833H10.0833V10.0833ZM11 1.83333C5.94001 1.83333 1.83334 5.94 1.83334 11C1.83334 16.06 5.94001 20.1667 11 20.1667C16.06 20.1667 20.1667 16.06 20.1667 11C20.1667 5.94 16.06 1.83333 11 1.83333ZM11 18.3333C6.95751 18.3333 3.66668 15.0425 3.66668 11C3.66668 6.9575 6.95751 3.66667 11 3.66667C15.0425 3.66667 18.3333 6.9575 18.3333 11C18.3333 15.0425 15.0425 18.3333 11 18.3333Z"
                fill={"#F5A05B"}
              />
            </svg>
          </div>
          <div className="opacity-75">
            {type == "mobile_money" && (
              <>{t("mobile_money_withdraw_success_message")}</>
            )}
            {type == "mfi" && <>{t("microfinance_directTransfer")}</>}
            {(type == "bank_transfer" || type == "manual_bank_transfer") && (
              <>{t("bank_success_withdraw_message")}</>
            )}
            {type == "delivery" && (
              <>{t("delivery_success_withdraw_message")}</>
            )}
          </div>
        </div>

        <button
          className="w-full rounded-lg btn btn-block text-white text-sm font-bold bg-[#037375]"
          onClick={() => {
            dispatch(openWithdraw(false));
            history.push("/");
          }}
        >
          {t("home_page")}
        </button>
      </div>
    </div>
  );
};

export default WithdrawSuccessScreen;
