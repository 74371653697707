import { toast } from "material-react-toastify";
import * as Sentry from "@sentry/browser";
import { performPDFDownload } from "../../utilities/help";
import { fetch_receipt } from "../../store/features/Transfer/Transfer";
import StatusButtons from "../StatusButtons";
import { useTranslation } from "react-i18next";
import { CircularProgress } from "@material-ui/core";
import { useMemo } from "react";

const CustomRepeatBtn = (props: any) => {
  const { t } = useTranslation();
  const { onClick, cancelPayOut, canceling, canCancel } = props;

  return (
    <div className="flex justify-between w-full gap-4">
      <button
        onClick={() => {
          if (onClick) {
            onClick();
          }
        }}
        className={"btn flex-center h-[52px] w-full bg-black rounded-xl"}
      >
        <div className="font-bold text-white text-sm">{t("Repeat")}</div>
      </button>

      {canCancel && (
        <button
          disabled={canceling}
          onClick={() => {
            if (cancelPayOut && !canceling) {
              cancelPayOut();
            }
          }}
          className={"btn flex-center h-[52px] w-full bg-[#DB1818] rounded-xl"}
        >
          {canceling ? (
            <CircularProgress className="text-white" size={20} />
          ) : (
            <div className="font-bold text-white text-sm">{t("cancel")}</div>
          )}
        </button>
      )}
    </div>
  );
};

const TransactionOperations = (props: any) => {
  const {
    fetchData,
    isSender,
    upload_documents,
    uploading,
    downloading,
    t,
    setDownloading,
    selectedTransfer,
    transfer,
    showDownloadBtn = false,
    showRepeat = true,
    transferCompleted = false,
  } = props;

  // show cancel only if payment is not completed, aborted and failed
  const canCancel = useMemo(() => {
    try {
      if (fetchData?.statuses && fetchData?.statuses?.length > 0) {
        const isPaymentComplete = fetchData?.statuses.find(
          (statusObj: any) =>
            statusObj.status == "payment_completed" &&
            statusObj.datetime != null
        );
        const isPaymentFailed = fetchData?.statuses.find(
          (statusObj: any) =>
            statusObj.status == "payment_failed" && statusObj.datetime != null
        );
        const isPaymentAborted = fetchData?.statuses.find(
          (statusObj: any) =>
            statusObj.status == "payment_aborted" && statusObj.datetime != null
        );
        if (isPaymentComplete || isPaymentFailed || isPaymentAborted) {
          return false;
        } else {
          return true;
        }
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  }, [fetchData]);

  // conditions to show download btn
  const canDownloadReceipt = useMemo(() => {
    try {
      if (
        [
          "deposit",
          "pay_out",
          "instant_transfer",
          "transfer",
          "direct transfer",
          "withdraw",
          "bulk_transfer",
        ].includes(fetchData?.operation_type?.toLowerCase()) &&
        fetchData?.operation_direction?.toLowerCase() == "sending" &&
        showDownloadBtn &&
        transferCompleted
      ) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  }, [fetchData, showDownloadBtn, transferCompleted]);

  const download_receipt = async () => {
    try {
      setDownloading(true);
      try {
        const result = await fetch_receipt(selectedTransfer.payment_id);
        performPDFDownload(result, selectedTransfer.payment_id);
        setDownloading(false);
        toast.success(t("download_done"));
      } catch (error: any) {
        toast.error(t("error_downloading"));
        setDownloading(false);
      }
    } catch (error: any) {
      Sentry.captureException(error);
    }
  };

  const DownloadBtn = () => {
    return (
      <>
        {isSender && (
          <button
            className="btn btn-block btn-black w-full bg-black text-white flex-center h-[52px] rounded-xl"
            onClick={() => download_receipt()}
            style={{
              backgroundColor: "#037375",
            }}
          >
            {downloading ? (
              <>
                <small className="ml-2 font-bold text-white">
                  {t("Please_Wait")}
                </small>
              </>
            ) : (
              <>
                <i className="m-0 text-white fa fa-download" />
                <div className="ml-2 font-bold text-white text-sm">
                  {t("download_receipt")}
                </div>
              </>
            )}
          </button>
        )}
      </>
    );
  };

  if (
    !(
      [
        "deposit",
        "pay_out",
        "instant_transfer",
        "withdraw",
        "bulk_transfer",
      ].includes(fetchData?.operation_type) && isSender
    ) &&
    fetchData?.status !== "payment_requested" &&
    !isSender
  ) {
    return <></>;
  } else {
    return (
      <>
        <div className="flex justify-end">
          {canDownloadReceipt && <DownloadBtn />}

          {(fetchData?.status === "payment_requested" ||
            fetchData?.operation_direction?.toLowerCase() == "sending") &&
            showRepeat && (
              <StatusButtons
                transfer={{
                  ...selectedTransfer,
                  payment_id: selectedTransfer.payment_id,
                }}
                transcationsObject={transfer}
                isSending={
                  fetchData?.operation_direction?.toLowerCase() == "sending"
                }
                isInTrasactionDetailsModal={true}
                showCancelBtn={false}
                circularProgressColor="white"
                customLoadingClass="flex justify-center items-center h-[52px] bg-black w-full rounded-lg"
                customMainClasses={
                  "btn-group flex items-center w-full rounded-lg"
                }
              >
                {(
                  repeat: any,
                  cancelPayOut: any,
                  showCancel: any,
                  canceling: any
                ) => (
                  <CustomRepeatBtn
                    onClick={repeat}
                    cancelPayOut={cancelPayOut}
                    canceling={canceling}
                    canCancel={canCancel}
                  />
                )}
              </StatusButtons>
            )}
        </div>
      </>
    );
  }
};

export default TransactionOperations;
